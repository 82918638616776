import React, { useState } from "react";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import CloseSvg from "./../../Components/Svg/closeSvg";
import ApiService from "../../Services/apiService";
import { useEffect } from "react";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import Parser from "html-react-parser";
import { Modal } from "react-bootstrap";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import { useSelector } from "react-redux";

export default function PageInfoBlock(props) {
	const { pageName, infoText, translationService, pageTitle, closeCb } = props;
	const { language } = useSelector(state => state.language);
	const [libraryData, setLibraryData] = useState([]);
	const [isShowInfoBlock, setIsShowInfoBlock] = useState(true);
	const [moreText, setMoreText] = useState("");

	useEffect(() => {
		if (pageName && pageName.trim().length) {
			getLibraryDataByPageName();
		}
		// return () => {
		//   setIsShowInfoBlock(null);
		// }
	}, [pageName, language]);

	const getLibraryDataByPageName = () => {
		ApiService.getLibraryDataByPageName(pageName)
			.then(response => {
				if (response && response.data) {
					setLibraryData(response.data);
				}
			})
			.catch(error => getFail(error));
	};

	const onShowMoreText = text => {
		if (!text.trim().length) {
			return false;
		}
		setMoreText(text);
	};

	const cancel = () => {
		setMoreText("");
	};

	const getFail = error => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	return infoText || (libraryData && libraryData.length) ? (
		isShowInfoBlock ? (
			<Auxiliary>
				<div className="light-blue-background blue-color blue-border p-3 mindalay--information d-flex align-items-center mt-2 pr--4 position-relative w-100">
					<_InfoSvg />
					{libraryData && libraryData.length
						? libraryData.map((item, index) => {
								if (item.name === "main_info") {
									return (
										<span key={index}>
											{Parser(item.displayName)}
											{item.description && item.description.trim().length ? (
												<Auxiliary>
													&nbsp;
													<span
														className="underline cursor-pointer"
														onClick={() => onShowMoreText(item.description)}>
														{translationService.translate("TR_LEARN_MORE")}
													</span>
												</Auxiliary>
											) : null}
										</span>
									);
								}
						  })
						: infoText
						? infoText
						: ""}

					<span
						className="mindalay--information-close-block"
						onClick={() => {
							setIsShowInfoBlock(false);
							closeCb && closeCb();
						}}>
						<CloseSvg />
					</span>
				</div>
				{moreText ? (
					<Modal
						size="lg"
						aria-labelledby="contained-modal-title-vcenter"
						centered
						onHide={cancel}
						show={true}>
						{pageTitle && (
							<Modal.Header>
								{" "}
								<h4>{pageTitle}</h4>
							</Modal.Header>
						)}
						<Modal.Body>
							<span>{Parser(moreText)}</span>
						</Modal.Body>
						<Modal.Footer>
							<button
								className="mindalay--btn-default mindalay--btn-small mb-2"
								onClick={cancel}>
								{translationService.translate("TR_CLOSE")}
							</button>
						</Modal.Footer>
					</Modal>
				) : null}
			</Auxiliary>
		) : (
			<span
				className="mindalay--information-open-block"
				onClick={() => {
					setIsShowInfoBlock(true);
					closeCb && closeCb();
				}}>
				<_InfoSvg />
			</span>
		)
	) : null;
}

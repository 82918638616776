// Staging ( windows server )
// -------------------------------------------------------------------------------------
//export const IDENTITY_API_URL_KEY = "https://identity.webinarsystems.ru";
//export const API_URL_KEY = "https://api.webinarsystems.ru";
//export const GAMIFY_URL_KEY = "https://gamifyup.webinarsystems.ru"; //api keys for requests
//export const GAMIFICATION_URL_KEY = "https://vnd.webinarsystems.ru"; //url
// -------------------------------------------------------------------------------------

// ARDY  -------------------------------------------------------------------------------------
 export const IDENTITY_API_URL_KEY = "https://identity.mindalay.net";
 export const API_URL_KEY = "https://api.mindalay.net";
 export const GAMIFY_URL_KEY = "https://api.gamifyup.com"; //api keys for requests
 export const GAMIFICATION_URL_KEY = "https://gamifyup.com"; //url
// -------------------------------------------------------------------------------------

// Production
// -------------------------------------------------------------------------------------
// export const IDENTITY_API_URL_KEY = "https://identity.mindalay.com";
// export const API_URL_KEY = "https://api.mindalay.com";
// export const GAMIFY_URL_KEY = "???";
// export const GAMIFICATION_URL_KEY = "???";
// -------------------------------------------------------------------------------------

// Development (Gigo)
// -------------------------------------------------------------------------------------
 //export const IDENTITY_API_URL_KEY = 'http://localhost:63002';
 //export const API_URL_KEY = 'https://localhost:44382';
 //export const GAMIFY_URL_KEY = 'http://localhost:58145'; //api keys for requests (beckend)
 //export const GAMIFICATION_URL_KEY = 'http://localhost:3001'; //url
// -------------------------------------------------------------------------------------

// Development Harut
// -------------------------------------------------------------------------------------
// export const IDENTITY_API_URL_KEY = "https://identity.webinarsystems.ru";
// export const API_URL_KEY = "https://api.webinarsystems.ru";
// export const GAMIFY_URL_KEY = "https://b832-87-241-138-217.ngrok-free.app"; //api keys for requests (beckend)
// export const GAMIFICATION_URL_KEY = "http://localhost:3001"; //url
// -------------------------------------------------------------------------------------

// Development Artur
// -------------------------------------------------------------------------------------
// export const IDENTITY_API_URL_KEY = "https://identity.webinarsystems.ru";
// export const API_URL_KEY = "https://1f8d-87-241-138-217.ngrok-free.app";
// export const GAMIFY_URL_KEY = "https://gamifyup.webinarsystems.ru"; //api keys for requests (beckend)
// export const GAMIFICATION_URL_KEY = "https://vnd.webinarsystems.ru"; //url
// -------------------------------------------------------------------------------------

export const WEBINAR_SCREEN_URL_KEY = "/cabinet/index.html";

import React, { useCallback, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { setCookie } from "../../../Services/cookieService";
import Dropdown from "../../../Components/Dropdown/dropdown";
import { setLanguage } from "../../../Store/Actions/language";
import { useDispatch, useSelector } from "react-redux";
import { LANGUAGE_KEY } from "../../../Constants/mainKeys";
import logoImg from "../../../assets/logo/footer-logo.png";
import TranslationService from "../../../Services/translationService";
import GuestApiService from "../../../Services/guestApiService";
import { setBackUrl } from "./../../../Store/Actions/main";

const AdminFooter = props => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const languages = useSelector(state => state.language.languages);
	const user = useSelector(state => state.user.user);
	const { settings } = useSelector(state => state.settings);

	const [menu, setMenu] = useState([]);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		GuestApiService.getFrontBottomPages().then(response => {
			setMenu(response.data);
		});
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const changeLanguage = isocode => {
		if (isocode) {
			localStorage.setItem(LANGUAGE_KEY, isocode);
			setLanguageHandler(isocode);
			const newPath = props.location.pathname
				.toString()
				.replace(props.match.path.toString(), `/${isocode}`);
			props.history.push(newPath.toString());
			if (
				props.location.pathname.includes("organization-admin/payment-package")
			) {
				window.location.reload();
			}
		}
	};

	const setUrl = () => {
		dispatch(setBackUrl(props.location.pathname));
	};

	const setLanguageHandler = useCallback(language => {
		dispatch(setLanguage(language));
	}, []);

	return translationService ? (
		<footer className="footer-margin-left">
			<div className="container-fluid">
				<div className="footer-top-body d-md-flex d-block mb-4">
					{/* {
					menu?.length
						? menu.map(menuItem => <ul key={menuItem.name}>
						<li>{translationService.translate(menuItem.name)}</li>
						{
							menuItem.children?.length
							? menuItem.children.map(children => <li key={children.name}>
								<Link
								onClick={setUrl}
								to={children.uri.includes("contact-us") ? `/${language}/${children.uri}` : `/${language}/${children.uri}/${children.id}`}
								>
								{translationService.translate(children.name)}
								</Link>
							</li>)
							: null
						}
						</ul>) : null
					} */}


					{
						// user && user.isOrganizationUser ? (
						// 	<ul>
						// 		<li>
						// 			<Link to={`/${language}/contact-us`} onClick={setUrl}>
						// 				{translationService.translate("TR_CONTACT_US")}
						// 			</Link>
						// 		</li>
						// 		<li>
						// 			<Link to={`/${language}/about`} onClick={setUrl}>
						// 				{translationService.translate("TR_ABOUT_PAGE")}
						// 			</Link>
						// 		</li>
						// 	</ul>
						// ) :

							menu?.length ? (
								menu.map(menuItem => (
									<ul key={menuItem.name}>
										<li>{translationService.translate(menuItem.name)}</li>
										{menuItem.children?.length
											? menuItem.children.map(children => (
												<li key={children.name}>
													<Link
														onClick={setUrl}
														to={
															children.uri.includes("contact-us")
																? `/${language}/${children.uri}`
																: `/${language}/${children.uri}/${children.id}`
														}>
														{translationService.translate(children.name)}
													</Link>
												</li>
											))
											: null}
									</ul>
								))
							) : null}
					<ul></ul>
					<ul>
						<li>
							<Dropdown
								items={languages}
								title={language.toUpperCase()}
								selected={changeLanguage}>
								<i className="fas fa-language mr-1"></i>
							</Dropdown>
						</li>
					</ul>
				</div>
				<div className="footer-bottom-body d-sm-flex align-items-start flex-column">
					<div className="footer-logo-wrapper" style={{ flex: "0" }}>
						{settings && settings?.footerLogoPath ? (
							<img src={settings.footerLogoPath} alt="/" />
						) : (
							<>
								<img src={logoImg} alt="/" />
								<span className="footer-brand-name">Mindalay</span>
							</>
						)}
					</div>
					{settings?.footerText && (
						<div className="my-2 footer-bottom-body ">
							<p className="footer-text">{settings?.footerText}</p>
						</div>
					)}
				</div>
				<div className="footer-bottom-body d-sm-flex">
					<p className="copyright">
						Copyright © <span>{new Date().getFullYear()}</span> Mindalay, Inc.
					</p>
				</div>
			</div>
		</footer>
	) : null;
};

export default withRouter(AdminFooter);

import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import * as moment from "moment";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ActionButton from "../../Components/ActionButton/actionButton";
import InputRadio from "../../Components/Inputs/inputRadio";
import DataListOption from "../../Components/SelectOption/dataListOption";
import MainService from "../../Services/mainService";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import DoubleArrowSvg from "../../Components/Svg/doubleArrowSvg";
import { addPageSpinner, removePageSpinner, addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { TR_CREATE_MESSAGE_KEY } from "../../Constants/translationKeys";
import {
  QUIZ_ATTEMPT_KEY,
  VIEW_KEY,
  ON_GOING_KEY,
  START_TIME_KEY,
  STATE_KEY,
  ATTEMPT_ID_KEY,
  GRACE_PERIOD_END_DATE_KEY,
  PURE_KEY
} from "../../Constants/urlKeys";
import {
  ALPHABET_KEY,
  BOOLEAN_KEY,
  CHOISE_NUMBERING_TYPES_ARR_KEY,
  ERROR_KEY,
  NUMBER_KEY,
  STRING_KEY,
  SUCCESS_KEY,
  TIME_TYPES_ARR_KEY,
  GRADING_METHODS_ARR_KEY,
  INFO_KEY
} from "../../Constants/mainKeys";
import Textarea from "../../Components/Inputs/textArea";
import Parser from 'html-react-parser';
import CloseSvg from './../../Components/Svg/closeSvg';
import ReactPaginate from "react-paginate";

class QuizAttempt extends Component {

  searchParams = new URLSearchParams(this.props.location.search);
  stateParam = this.searchParams.get(STATE_KEY);
  choiseNumbering = CHOISE_NUMBERING_TYPES_ARR_KEY;
  timeLimitTypes = TIME_TYPES_ARR_KEY;
  alphabet = ALPHABET_KEY;
  gradeingMethods = GRADING_METHODS_ARR_KEY;
  interval;
  windowWidth = window.innerWidth;

  state = {
    currentQuestionIndex: 0,
    questions: [],
    questionsInfo: [],
    quizId: +this.props.match.params.quizId,
    quizState: this.stateParam ? +this.stateParam : null,
    quizName: '',
    quizData: null,
    reviewAndSubmit: false,
    quizAttemptId: null,
    isAttemptFinished: this.stateParam ? +this.stateParam === 2 : false,
    canBeChecked: null,
    isInvalidSubmit: false,
    invalidQuestionId: null,
    spinnerId: uuid(),
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
    failedFields: [],
    checkedQuestionIds: [],
    translationService: null,
    answerMaxLength: 1000,
    zoomImagePath: null,
    activePageNumber: 0,
    form: {
      formQuestions: []
    }
  };

  componentDidMount() {
    this.setTranslations();
    const { quizState } = this.state;
    const onGoingId = this.searchParams.get(ON_GOING_KEY);
    const attemptId = this.searchParams.get(ATTEMPT_ID_KEY);
    if (onGoingId) {
      this.openQuizAttempt(onGoingId, ApiService.getOnGoingQuizAttempt);
    } else if ((quizState === 1 || quizState === 2) && attemptId) {
      this.openQuizAttempt(attemptId, ApiService.getReviewQuizAttempt);
    }
    if (!onGoingId && !attemptId) {
      this.props.history.push(`/${this.props.language}`);
    }
    window.addEventListener("beforeunload", this.onUnload);
    window.addEventListener('keydown', this.zoomOut);
  }

  componentDidUpdate() {
    this.setTranslations();
    window.addEventListener("beforeunload", this.onUnload);

    var questionDropdownBlocks = document.querySelectorAll('.question-dropdown-block')
    if (questionDropdownBlocks && questionDropdownBlocks.length) {
      for (let i = 0; i < questionDropdownBlocks.length; i++) {
        questionDropdownBlocks[i].addEventListener("click", (event) => {
          if (event.target.tagName.toLowerCase() === 'img') {
            this.getImageSrc(event.target);
          }
        }, { once: true });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
    window.removeEventListener('keydown', this.zoomOut);
    clearInterval(this.interval);
    var questionDropdownBlocks = document.querySelectorAll('.question-dropdown-block')
    if (questionDropdownBlocks && questionDropdownBlocks.length) {
      for (let i = 0; i < questionDropdownBlocks.length; i++) {
        questionDropdownBlocks[i].removeEventListener("click", (event) => {
          if (event.target.tagName.toLowerCase() === 'img') {
            this.getImageSrc(event.target);
          }
        });
      }
    }
  }

  getCheckedquestions = (attemptId) => {
    if (!attemptId) { return false; }
    ApiService.getCheckedquestions(+attemptId).then(response => {
      if (response && response.data) {
        this.setState({
          checkedQuestionIds: response.data
        })
      }
    }).catch(error => this.getFail(error))
  };

  getImageSrc = (element) => {
    if (element && element.src) {
      this.setState({ zoomImagePath: element.src });
    }
  }

  zoomOut = (event) => {
    if (event.key === "Escape") {
      this.hideZoomImageVideo();
    }
  }

  hideZoomImageVideo = () => {
    this.setState({ zoomImagePath: null });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  openQuizAttempt = (quizAttemptId, methodRef) => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    methodRef(quizAttemptId).then(response => {
      if (response && response.data && response.data.state === 0) {
        this.getCheckedquestions(quizAttemptId);
      }
      this.props.removePageSpinner(spinnerId);
      this.getQuizById(this.state.quizId, response.data);
    }).catch(e => this.getFail(spinnerId, e));
  }

  getQuizById = (quizId, attemptData) => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getQuizById(quizId).then(response => {
      const questions = response.data.questions;
      let questionsInfo = attemptData.questionsInfo;
      questionsInfo = questionsInfo && questionsInfo.filter(questionInfo => questions.findIndex(data => data.id === questionInfo.questionId) !== -1 ? questionInfo : null);
      let formQuestions = [];
      const checkedQuestionIds = [...this.state.checkedQuestionIds];
      if (questions && questions.length) {
        formQuestions = this.addQuestionsFormFields(questions, attemptData.id, questionsInfo);
      }
      questions.forEach(question => {
        if (question.questionType === 1) {
          question.matchingQuestionAnswers = question.questionAnswers.filter(data => data.question);
        }
      });
      questionsInfo.forEach(questionInfo => {
        const selectedQuestion = questions && questions.find(data => data.id === questionInfo.questionId);
        if (questionInfo.isShuffle) {
          const questionAnswers = this.shuffle([...selectedQuestion.questionAnswers]);
          selectedQuestion.questionAnswers = [...questionAnswers];
        }
        // if (questionInfo.tryAgain) {
        //   checkedQuestionIds.push(questionInfo.questionId);
        // }
        if (questionInfo.questionType === 0 || questionInfo.questionType === 2) {
          // set radio buttons value && short answer value  
          const questionIndex = questions.findIndex(data => data.id === questionInfo.questionId);
          if (questionIndex !== -1) {
            const { attemptAnswers } = formQuestions[questionIndex];
            const selectedFormQuestion = { ...formQuestions[questionIndex] };
            const currentAnswer = attemptAnswers && attemptAnswers.length && attemptAnswers.find(answer => answer.questionId === selectedFormQuestion.questionId);
            if (currentAnswer) {
              selectedFormQuestion.answerId = currentAnswer.answerId ? currentAnswer.answerId : null;
              selectedFormQuestion.answerIds = currentAnswer.answerIds ? currentAnswer.answerIds : [];
              selectedFormQuestion.shortAnswer = currentAnswer.shortAnswer ? currentAnswer.shortAnswer : "";
              formQuestions[questionIndex] = selectedFormQuestion;
            }
          }
        }
        if (questionInfo.questionType === 1) {
          // set matching answers
          const questionIndex = questions.findIndex(data => data.id === questionInfo.questionId);
          if (questionIndex !== -1) {
            const selectedFormQuestion = { ...formQuestions[questionIndex] };
            selectedFormQuestion.attemptAnswers &&
              selectedFormQuestion.attemptAnswers.length &&
              selectedFormQuestion.attemptAnswers.forEach(attemptAnswer => {
                selectedFormQuestion.matchingAnswers.push({ question: attemptAnswer.answerId, answer: attemptAnswer.wrongAnswerId ? attemptAnswer.wrongAnswerId : attemptAnswer.answerId })
              })
            formQuestions[questionIndex] = selectedFormQuestion;
          }
        }
      });
      const timeLimit = response.data.timeLimit;
      const timeLimitEntity = response.data.timeLimitEntity;
      const startTime = this.searchParams.get(START_TIME_KEY) || attemptData.startTime;
      const gracePeriodEndDate = this.searchParams.get(GRACE_PERIOD_END_DATE_KEY);
      if (timeLimit && (timeLimitEntity || timeLimitEntity === 0 || startTime || gracePeriodEndDate)) {
        const dateNow = (startTime && MainService.convertUTCDateToLocalDate(new Date(startTime)))
          || (gracePeriodEndDate && MainService.convertUTCDateToLocalDate(new Date(gracePeriodEndDate)))
          || new Date();
        switch (timeLimitEntity) {
          case 0:
            dateNow.setSeconds(dateNow.getSeconds() + timeLimit);
            break;
          case 1:
            dateNow.setMinutes(dateNow.getMinutes() + timeLimit);
            break;
          case 2:
            dateNow.setHours(dateNow.getHours() + timeLimit);
            break;
          case 3:
            dateNow.setDate(dateNow.getDate() + timeLimit);
            break;
          case 4:
            dateNow.setDate(dateNow.getDate() + (timeLimit * 7));
            break;
          default:
            break
        }
        this.setCountDown(dateNow);
      }
      this.props.removePageSpinner(spinnerId);
      this.setState(prevState => ({
        ...prevState,
        questions,
        questionsInfo,
        checkedQuestionIds,
        canBeChecked: attemptData.canBeChecked,
        quizName: response.data.name,
        quizData: response.data,
        quizAttemptId: attemptData.id,
        form: {
          ...prevState.form,
          formQuestions
        }
      }));
    }).catch(e => this.getFail(spinnerId, e));
  }

  addQuestionsFormFields = (questions, quizAttemptId, questionsInfo) => {
    const formQuestions = [];
    questions.forEach(question => {
      const currentQuestionInfo = questionsInfo.find(data => data.questionId === question.id);
      formQuestions.push({
        quizAttemptId: quizAttemptId,
        questionId: question.id,
        questionType: question.questionType,
        answerId: null,
        isCorrect: null,
        shortAnswer: '',
        matchingAnswers: [],
        answerIds: [],
        passed: currentQuestionInfo && currentQuestionInfo.passed,
        isShuffle: currentQuestionInfo && currentQuestionInfo.isShuffle,
        choiceNumbering: currentQuestionInfo && currentQuestionInfo.choiceNumbering,
        tryAgain: currentQuestionInfo && currentQuestionInfo.tryAgain,
        mark: currentQuestionInfo && currentQuestionInfo.mark,
        markResponse: currentQuestionInfo && currentQuestionInfo.markResponse,
        whetherCorrect: currentQuestionInfo && currentQuestionInfo.whetherCorrect,
        hint: currentQuestionInfo && currentQuestionInfo.hint,
        generalFeedback: currentQuestionInfo && currentQuestionInfo.generalFeedbackResponse,
        attemptAnswers: currentQuestionInfo && currentQuestionInfo.attemptAnswers,
      });
    });
    return formQuestions;
  }

  addQuestionFormCheckData = (questionId, questionsInfo) => {
    const selectedFormQuestion = { ...this.state.form.formQuestions.find(data => data.questionId === questionId) };
    const formQuestion = {
      ...selectedFormQuestion,
      passed: questionsInfo && questionsInfo.passed,
      mark: questionsInfo && questionsInfo.mark,
      markResponse: questionsInfo && questionsInfo.markResponse,
      whetherCorrect: questionsInfo && questionsInfo.whetherCorrect,
      hint: questionsInfo && questionsInfo.hint,
      generalFeedback: questionsInfo && questionsInfo.generalFeedbackResponse,
      tryAgain: questionsInfo && questionsInfo.tryAgain,
      attemptAnswers: questionsInfo && questionsInfo.attemptAnswers,
    };
    return formQuestion;
  }

  questionTryAgain = questionIndex => {
    const selectedQuestion = { ...this.state.questions[questionIndex] };
    if (!selectedQuestion) { return; }
    const selectedFormQuestion = { ...this.state.form.formQuestions.find(data => data.questionId === selectedQuestion.id) };
    if (selectedFormQuestion) {
      const questions = [...this.state.questions];
      if (selectedFormQuestion.isShuffle) {
        const questionAnswers = this.shuffle([...selectedQuestion.questionAnswers]);
        selectedQuestion.questionAnswers = [...questionAnswers];
      }
      const checkedQuestionIds = [...this.state.checkedQuestionIds];
      const checkedQuestionIndex = checkedQuestionIds.indexOf(selectedQuestion.id);
      if (checkedQuestionIndex !== -1) {
        checkedQuestionIds.splice(checkedQuestionIndex, 1);
      }
      questions[questionIndex] = selectedQuestion;
      if (selectedFormQuestion) {
        selectedFormQuestion.tryAgain = false;
        selectedFormQuestion.answerId = null;
        selectedFormQuestion.answerIds = [];
        selectedFormQuestion.matchingAnswers = [];
        selectedFormQuestion.shortAnswer = '';
        selectedFormQuestion.isCorrect = null;
        selectedFormQuestion.mark = null;
        selectedFormQuestion.whetherCorrect = '';
        selectedFormQuestion.hint = '';
        selectedFormQuestion.generalFeedback = '';
        selectedFormQuestion.attemptAnswers = [];
        const form = { ...this.state.form };
        const formQuestions = [...this.state.form.formQuestions];
        formQuestions[questionIndex] = selectedFormQuestion;
        form.formQuestions = formQuestions;
        this.setState({ form, questions, checkedQuestionIds });
      }
    }
  }

  onTrueFalseRadioChange = (event, questionIndex) => {
    if (questionIndex !== -1 && typeof JSON.parse(event.target.value) === BOOLEAN_KEY) {
      const selectedFormQuestion = { ...this.state.form.formQuestions[questionIndex] };
      selectedFormQuestion[event.target.name] = JSON.parse(event.target.value);
      const formQuestions = [...this.state.form.formQuestions];
      formQuestions[questionIndex] = selectedFormQuestion;
      const failedFields = this.removeFailedFields(event.target.name);
      this.setState(prevState => ({
        ...prevState,
        failedFields,
        form: {
          ...prevState.form,
          formQuestions
        }
      }));
    }
  }

  onRadioChange = (event, questionIndex, type) => {
    if (questionIndex !== -1 && (typeof +event.target.value === type || typeof JSON.parse(event.target.value) === type)) {
      const selectedFormQuestion = { ...this.state.form.formQuestions[questionIndex] };
      selectedFormQuestion[event.target.name] = JSON.parse(event.target.value);
      const formQuestions = [...this.state.form.formQuestions];
      formQuestions[questionIndex] = selectedFormQuestion;
      const failedFields = this.removeFailedFields(event.target.name);
      this.setState(prevState => ({
        ...prevState,
        failedFields,
        form: {
          ...prevState.form,
          formQuestions
        }
      }));
    }
  }

  onCheckboxChange = (event, questionIndex, answerId) => {
    if (questionIndex !== -1 && typeof event.target.checked === BOOLEAN_KEY && typeof +answerId === NUMBER_KEY) {
      const selectedFormQuestion = { ...this.state.form.formQuestions[questionIndex] };
      let answerIds = [...selectedFormQuestion[event.target.name]];
      if (event.target.checked && !answerIds.includes(answerId)) {
        answerIds.push(answerId);
      } else if (!event.target.checked && answerIds.includes(answerId)) {
        answerIds = answerIds.filter(data => data !== answerId);
      }
      selectedFormQuestion[event.target.name] = answerIds;
      const formQuestions = [...this.state.form.formQuestions];
      formQuestions[questionIndex] = selectedFormQuestion;
      const failedFields = this.removeFailedFields(event.target.name);
      this.setState(prevState => ({
        ...prevState,
        failedFields,
        form: {
          ...prevState.form,
          formQuestions
        }
      }));
    }
  }

  onShortAnswerChange = (event, questionIndex, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    if (questionIndex !== -1 && typeof event.target.value === STRING_KEY) {
      const selectedFormQuestion = { ...this.state.form.formQuestions[questionIndex] };
      selectedFormQuestion[event.target.name] = event.target.value;
      const formQuestions = [...this.state.form.formQuestions];
      formQuestions[questionIndex] = selectedFormQuestion;
      const failedFields = this.removeFailedFields(event.target.name);
      this.setState(prevState => ({
        ...prevState,
        failedFields,
        form: {
          ...prevState.form,
          formQuestions
        }
      }));
    }
  }

  // onMatchingChange = (event, questionIndex, answer) => {
  //   if (questionIndex !== -1 && typeof +event.target.value === NUMBER_KEY) {
  //     const selectedFormQuestion = { ...this.state.form.formQuestions[questionIndex] };
  //     let matchingAnswers = [...selectedFormQuestion[event.target.name]];
  //     const selectedAnswerQuestionIndex = matchingAnswers.findIndex(data => data.question === answer.id);
  //     if (selectedAnswerQuestionIndex !== -1) {
  //       const selectedAnswerQuestion = matchingAnswers[selectedAnswerQuestionIndex];
  //       selectedAnswerQuestion.answer = +event.target.value;
  //     } else {
  //       const newAnswerQuestion = { answer: +event.target.value, question: +answer.id };
  //       matchingAnswers.push(newAnswerQuestion);
  //     }
  //     const formQuestions = [...this.state.form.formQuestions];
  //     selectedFormQuestion[event.target.name] = [...matchingAnswers];
  //     formQuestions[questionIndex] = selectedFormQuestion;
  //     const failedFields = this.removeFailedFields(event.target.name);
  //     this.setState(prevState => ({
  //       ...prevState,
  //       failedFields,
  //       form: {
  //         ...prevState.form,
  //         formQuestions
  //       }
  //     }));
  //   }
  // }

  onMatchingChange = (value, name, questionIndex, answer) => {
    if (questionIndex !== -1 && typeof +value === NUMBER_KEY) {
      const selectedFormQuestion = { ...this.state.form.formQuestions[questionIndex] };
      let matchingAnswers = [...selectedFormQuestion[name]];
      const selectedAnswerQuestionIndex = matchingAnswers.findIndex(data => data.question === answer.id);
      if (selectedAnswerQuestionIndex !== -1) {
        const selectedAnswerQuestion = matchingAnswers[selectedAnswerQuestionIndex];
        selectedAnswerQuestion.answer = +value;
      } else {
        const newAnswerQuestion = { answer: +value, question: +answer.id };
        matchingAnswers.push(newAnswerQuestion);
      }
      const formQuestions = [...this.state.form.formQuestions];
      selectedFormQuestion[name] = [...matchingAnswers];
      formQuestions[questionIndex] = selectedFormQuestion;
      const failedFields = this.removeFailedFields(name);
      this.setState(prevState => ({
        ...prevState,
        failedFields,
        form: {
          ...prevState.form,
          formQuestions
        }
      }));
    }
  }


  check = (questionIndex, fieldName) => {
    const selectedFormQuestion = { ...this.state.form.formQuestions[questionIndex] };
    if (selectedFormQuestion.passed
      || selectedFormQuestion[fieldName] === null
      || selectedFormQuestion[fieldName] === ''
      || (Array.isArray(selectedFormQuestion[fieldName])
        && !selectedFormQuestion[fieldName].length)) {
      this.setState({ isInvalidSubmit: true, invalidQuestionId: selectedFormQuestion.questionId });
    }
    else if (fieldName === 'matchingAnswers' && selectedFormQuestion[fieldName].length !== this.state.questions[questionIndex].matchingQuestionAnswers.length) {
      this.setState({ isInvalidSubmit: true, invalidQuestionId: selectedFormQuestion.id });
    }
    else {
      const spinnerId = `attemptCheck_${questionIndex}`;
      this.props.addButtonSpinner(spinnerId);
      const form = {
        quizAttemptId: selectedFormQuestion.quizAttemptId,
        questionId: selectedFormQuestion.questionId,
        [fieldName]: selectedFormQuestion[fieldName]
      };
      ApiService.checkQuizQuestion(form).then(response => {
        const { translationService } = this.state;
        const questionInfo = response.data;
        const formQuestion = questionInfo && this.addQuestionFormCheckData(selectedFormQuestion.questionId, questionInfo);
        const formQuestionIndex = this.state.form.formQuestions.findIndex(data => data.questionId === selectedFormQuestion.questionId);
        const checkedQuestionIds = [...this.state.checkedQuestionIds];
        checkedQuestionIds.push(selectedFormQuestion.questionId);
        if (formQuestionIndex !== -1) {
          const formQuestions = [...this.state.form.formQuestions];
          formQuestions[formQuestionIndex] = { ...formQuestion };
          this.setState(prevState => ({
            ...prevState,
            checkedQuestionIds,
            form: {
              ...prevState.form,
              formQuestions
            }
          }));
        }
        this.props.removeButtonSpinner(spinnerId);
        // AlertService.alert(INFO_KEY, translationService.translate(TR_CREATE_MESSAGE_KEY));
      }).catch(e => this.submitFail(spinnerId, e));
    }
  }

  onSubmit = () => {
    const formQuestions = [...this.state.form.formQuestions];
    const { spinnerId, quizAttemptId } = this.state;
    const form = [];

    if (!quizAttemptId) { return; }

    formQuestions.forEach(formQuestion => {
      let fieldName = '';
      switch (formQuestion.questionType) {
        case 0:
          if (formQuestion.answerIds && formQuestion.answerIds.length) {
            fieldName = 'answerIds';
          } else if (formQuestion.answerId) {
            fieldName = 'answerId';
          }
          break;
        case 1:
          fieldName = 'matchingAnswers';
          break;
        case 2:
          fieldName = 'shortAnswer';
          break;
        case 3:
          fieldName = 'isCorrect';
          break;
        default:
          break
      }

      if (!this.state.checkedQuestionIds.includes(formQuestion.questionId)) {
        const questionFormData = {
          quizAttemptId: formQuestion.quizAttemptId,
          questionId: formQuestion.questionId,
          [fieldName]: formQuestion[fieldName]
        };
        form.push(questionFormData);
      }
    });
    this.props.addButtonSpinner(spinnerId);

    ApiService.submitQuizQuestion(quizAttemptId, form).then(response => {
      this.props.removeButtonSpinner(spinnerId);
      const { questions, quizAttemptId } = this.state;
      const formQuestions = this.addQuestionsFormFields(questions, quizAttemptId, response.data.questionsInfo);
      this.setState(prevState => ({ ...prevState, isAttemptFinished: true, form: { ...prevState.form, formQuestions } }));
      // this.goBack();
    }).catch(e => this.submitFail(spinnerId, e));
  }

  onUnload = event => {
    event.preventDefault();
    const { isAttemptFinished, isQuizOverdue, translationService } = this.state;
    if (isAttemptFinished || isQuizOverdue) {
      return;
    }
    const message = translationService.translate("TR_AFTER_LEAVE_PAGE_CHANGES_BE_LOST");
    if (typeof event == 'undefined') {
      event = window.event;
    }
    if (event) {
      event.returnValue = '';
    }
    return message;
  }

  onLeaveAttempt = () => {
    const { translationService, isAttemptFinished, isQuizOverdue } = this.state;
    if (isAttemptFinished || isQuizOverdue) {
      this.goBack();
    } else {
      AlertService.alertConfirm(
        translationService.translate("TR_AFTER_LEAVE_PAGE_CHANGES_NOT_BE_SAVED"),
        "",
        translationService.translate('TR_GOBACK'),
        translationService.translate('TR_CANCEL')
      ).then(() => {
        this.goBack();
      });
    }
  }

  openPrevQuestion = currentQuestionIndex => {
    if (currentQuestionIndex !== -1) {
      this.setState({ currentQuestionIndex: currentQuestionIndex - 1 !== -1 ? currentQuestionIndex - 1 : currentQuestionIndex });
    }
  }

  showReviewAndSubmit = (quizData) => {
    this.setState({ reviewAndSubmit: true });
  }

  cancelReviewAndSubmit = () => {
    this.setState({ reviewAndSubmit: false });
  }

  openNextQuestion = currentQuestionIndex => {
    if (currentQuestionIndex !== -1) {
      this.setState({
        currentQuestionIndex: currentQuestionIndex + 1 !== this.state.questionsInfo.length
          ? currentQuestionIndex + 1
          : currentQuestionIndex
      });
    }
  }

  openCurrentQuestion = currentQuestionIndex => {
    currentQuestionIndex !== -1 && this.setState({ currentQuestionIndex });
  }

  handlePageClick = (event) => {
    this.openCurrentQuestion(event.selected);
    this.setState({ activePageNumber: event.selected });
  }

  shuffle = prevAnswers => {
    const answers = [...prevAnswers];
    for (let i = answers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = { ...answers[i] };
      answers[i] = { ...answers[j] };
      answers[j] = temp;
    }
    return answers;
  };

  setCountDown = date => {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    const countDown = new Date(date).getTime();
    this.interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDown - now;
      const days = Math.floor(distance / (day));
      const hours = Math.floor((distance % (day)) / (hour));
      const minutes = Math.floor((distance % (hour)) / (minute));
      const seconds = Math.floor((distance % (minute)) / second) + 1;
      this.setState({ days, hours, minutes, seconds });
      if (distance < 0 && (this.state.quizData && this.state.quizData.timeLimit)) {
        (this.state.quizState !== 1 && this.state.quizState !== 2) && this.goBack();
        clearInterval(this.interval);
      }
    }, 1000);
  };

  convertHtmeToString = (htmlElement) => {
    let strippedHtml = htmlElement.replace(/<[^>]+>/g, '');
    if (strippedHtml.includes("&nbsp;")) {
      strippedHtml = strippedHtml.replace("&nbsp;", " ")
    }
    if (strippedHtml.includes("\n")) {
      strippedHtml = strippedHtml.replace("\n", "")
    }

    return strippedHtml;
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  submitFail = (spinnerId, errors) => {
    this.props.removeButtonSpinner(spinnerId);
    this.setState({ isDisabled: false });
    const failedFields = errors;
    this.setState({ failedFields });
    errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
  };

  getFail = (spinnerId, error) => {
    const { language, pureLayoutBackUrl } = this.props;
    this.props.removePageSpinner(spinnerId);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    error && pureLayoutBackUrl
      ? this.props.history.push(pureLayoutBackUrl)
      : this.props.history.push(`/${language}}`)
  };

  goBack = () => {
    const { history, language, match } = this.props;
    history.push(`/${language}/${PURE_KEY}/${QUIZ_ATTEMPT_KEY}/${VIEW_KEY}/${match.params.courseId}/${this.state.quizId}`);
  };

  render() {
    const {
      questions,
      questionsInfo,
      currentQuestionIndex,
      canBeChecked,
      translationService,
      failedFields,
      isInvalidSubmit,
      invalidQuestionId,
      spinnerId,
      days,
      hours,
      minutes,
      seconds,
      isAttemptFinished,
      quizName,
      quizState,
      reviewAndSubmit,
      quizData,
      checkedQuestionIds,
      answerMaxLength,
      zoomImagePath,
      activePageNumber
    } = this.state;


    const { formQuestions } = this.state.form;
    const isQuizOverdue = quizState && quizState === 1;
    const isReview = isAttemptFinished || isQuizOverdue || reviewAndSubmit;

    return translationService && questionsInfo.length && questions.length ?
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="background box-shadow-4 mb-lg-0 mb-3">
              <ul className="quiz-fildest-information">
                {
                  quizData.timeLimit ? <fieldset>
                    <legend>{translationService.translate("TR_TIME_LIMIT")}</legend>
                    <p>
                      <span className="mr-1">{quizData.timeLimit}</span>
                      {
                        (() => {
                          switch (quizData.timeLimitEntity) {
                            case 0:
                              return translationService.translate("TR_SECONDS");
                            case 1:
                              return translationService.translate("TR_MINUTES");
                            case 2:
                              return translationService.translate("TR_HOURS");
                            case 3:
                              return translationService.translate("TR_DAYS");
                            case 4:
                              return translationService.translate("TR_WEEKS");
                            default:
                              break
                          }
                        })()
                      }
                    </p>
                  </fieldset> : null
                }
                {
                  zoomImagePath ?
                    <div className="zoom-image-container" onClick={this.hideZoomImageVideo}>
                      <div className="close-svg"><CloseSvg /></div>
                      <img src={zoomImagePath} alt="/" onClick={(event) => event.stopPropagation()} />
                    </div>
                    : null
                }
                {
                  quizData.openDate ? <fieldset>
                    <legend>{translationService.translate("TR_START_DATE")}</legend>
                    <p>{moment(MainService.convertUTCDateToLocalDate(new Date(quizData.openDate))).format("YYYY-MM-DD HH:mm:ss")}</p>
                  </fieldset> : null
                }
                {
                  quizData.closeDate ? <fieldset>
                    <legend>{translationService.translate("TR_END_DATE")}</legend>
                    <p>{moment(MainService.convertUTCDateToLocalDate(new Date(quizData.closeDate))).format("YYYY-MM-DD HH:mm:ss")}</p>
                  </fieldset> : null
                }
                {
                  quizData.questions && quizData.questions.length
                    ? <fieldset>
                      <legend>{translationService.translate("TR_QUESTIONS_COUNT")}</legend>
                      <p>{quizData.questions.length}</p>
                    </fieldset> : null
                }
                {
                  quizData.gradingMethod || quizData.gradingMethod === 0
                    ? <fieldset>
                      <legend>{translationService.translate("TR_GRADING_METHOD")}</legend>
                      <p>{translationService.translate(this.gradeingMethods[quizData.gradingMethod])}</p>
                    </fieldset> : null
                }
              </ul>
            </div>
          </div>
          <div className="col-lg-9 col-12">
            <div className="background box-shadow-4 content position-relative overflow-hidden">
              <div className="content-title">
                <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.onLeaveAttempt}><ArrowBackSvg /></Link>
                <h3 className="content-title p-0 word-break-break-word">{quizName}</h3>
              </div>
              <hr />
              {
                reviewAndSubmit ? <div className="content-title d-md-flex d-block justify-content-between pt-2 mb-2">
                  <h6>{translationService.translate("TR_REVIEW_QUESTIONS_AND_SUBMIT")}</h6>
                </div> : null
              }
              {
                // !isAttemptFinished && (days || hours || minutes || seconds)
                !isAttemptFinished && (quizData && quizData.timeLimit) && (days || hours || minutes || seconds)
                  ? <Auxiliary>
                    <hr />
                    <div className="countdown-wrapper">
                      <div className="countdown-item">
                        <p className="timer-number">{days < 0 ? "0" : days}</p>
                        <p className="time-name">{translationService.translate("TR_DAYS")}</p>
                      </div>
                      <div className="countdown-item">
                        <p className="timer-number">{hours < 0 ? "0" : hours}</p>
                        <p className="time-name">{translationService.translate("TR_HOURS")}</p>
                      </div>
                      <div className="countdown-item">
                        <p className="timer-number">{minutes < 0 ? "0" : minutes}</p>
                        <p className="time-name">{translationService.translate("TR_MINUTES")}</p>
                      </div>
                      <div className="countdown-item">
                        <p className="timer-number">{seconds < 0 ? "0" : seconds}</p>
                        <p className="time-name">{translationService.translate("TR_SECONDS")}</p>
                      </div>
                    </div>
                    <hr />
                  </Auxiliary> : null
              }
              {/* after  finish attempt*/}
              {
                questionsInfo && questionsInfo.map((questionInfo, questionInfoIndex) => {
                  const question = questions.find(data => data.id === questionInfo.questionId);
                  const questionIndex = questions.findIndex(data => data.id === questionInfo.questionId);
                  if ((questionIndex === -1 || (questionIndex !== currentQuestionIndex)) && !isReview) { return false; }
                  const {
                    isCorrect,
                    shortAnswer,
                    matchingAnswers,
                    answerIds,
                    answerId,
                    passed,
                    mark,
                    markResponse,
                    whetherCorrect,
                    hint,
                    generalFeedback,
                    attemptAnswers,
                    tryAgain,
                    choiceNumbering,
                  } = formQuestions[questionIndex];

                  const isDisabled = passed || tryAgain || isAttemptFinished || isQuizOverdue || isReview || reviewAndSubmit;
                  const canTryAgain = !isAttemptFinished && tryAgain && !isQuizOverdue;
                  const markMessage = mark ? translationService.translate("TR_QUESTION_MARK_MSG").replace('{0}', markResponse).replace('{1}', mark) : null;

                  return <Auxiliary key={`${question.id}_${question.languageId}`}>
                    <div className="content-body py-0">

                      <div className="question-wrapper">
                        {/* <p className="question-name">{question.name}</p>
                        <p className="disable-user-select">{question.text}</p> */}

                        <span className="question-dropdown-block">
                          <span className="question-name">{Parser(question.name)}</span>
                        </span>

                        <span className="question-dropdown-block">
                          <span className="disable-user-select">{Parser(question.text)}</span>
                        </span>

                        <p className="question-options">
                          {
                            checkedQuestionIds.find(id => id === question.id) ? <Auxiliary>
                              <span>{translationService.translate("TR_ANSWER_SAVED")}</span>
                            </Auxiliary> : null
                          }
                          <span>{markResponse || markResponse === 0 ? markMessage : null}</span>
                        </p>
                      </div>
                      <hr />
                      <form className="question-form">
                        <div className="row">
                          <div className="col-12">
                            {
                              (() => {
                                switch (question.questionType) {
                                  case 0: // Multi
                                    return question.questionAnswers && question.questionAnswers.map((questionAnswer, questionAnswersIndex) => {
                                      const currentAnswerAttempt = attemptAnswers && attemptAnswers.find(data => data.answerId === questionAnswer.id);
                                      return <div
                                        key={questionAnswer.id}
                                        className={`question-body-container custom-inp-style pl-0 custom-control
                                              ${question.isMultiAnswer ? 'custom-checkbox' : 'custom-radio'}
                                            `}
                                      >
                                        {
                                          question.isMultiAnswer
                                            ? <input
                                              type="checkbox"
                                              id={`multi_${questionAnswer.id}`}
                                              name="answerIds"
                                              disabled={isDisabled}
                                              checked={(currentAnswerAttempt ? currentAnswerAttempt.answerId : null) || answerIds.includes(questionAnswer.id)}
                                              className={`custom-control-input ${isInvalidSubmit && invalidQuestionId === question.id && !answerIds.length ? "is-invalid error-bordered" : ""}`}
                                              onChange={event => !isDisabled && this.onCheckboxChange(event, questionIndex, questionAnswer.id)}
                                            />
                                            : <input
                                              type="radio"
                                              id={`multi_${questionAnswer.id}`}
                                              name="answerId"
                                              value={questionAnswer.id}
                                              disabled={isDisabled}
                                              checked={questionAnswer.id === answerId || questionAnswer.id === (currentAnswerAttempt && currentAnswerAttempt.answerId)}
                                              className={`custom-control-input ${isInvalidSubmit && invalidQuestionId === question.id && !answerId ? "is-invalid error-bordered" : ""}`}
                                              onChange={event => !isDisabled && this.onRadioChange(event, questionIndex, NUMBER_KEY)}
                                            />
                                        }
                                        <label
                                          htmlFor={`multi_${questionAnswer.id}`}
                                          className={`custom-control-label question-label-wrapper
                                               ${currentAnswerAttempt && currentAnswerAttempt.isCorrect !== null
                                              ? currentAnswerAttempt.isCorrect
                                                ? "light-green-background" : "light-red-background"
                                              : "light-gray-background"}
                                            `}
                                        >
                                          {
                                            choiceNumbering
                                              ? <p className="question-number">
                                                {(() => {
                                                  switch (choiceNumbering) {
                                                    case 1:
                                                      return this.alphabet[questionAnswersIndex];
                                                    case 2:
                                                      return this.alphabet[questionAnswersIndex].toUpperCase();
                                                    case 3:
                                                      return questionAnswersIndex + 1;
                                                    default:
                                                      break
                                                  }
                                                })()}.
                                              </p> : null
                                          }
                                          <div className="d-flex question-body">
                                            <span className={`form-check-label
                                                    ${currentAnswerAttempt && currentAnswerAttempt.isCorrect !== null ? currentAnswerAttempt.isCorrect
                                                ? "green-color"
                                                : "red-color"
                                                : "gray-color"}
                                                     ${isInvalidSubmit && invalidQuestionId === question.id && (question.isMultiAnswer ? !answerIds.length : !answerId) ? "fail" : ""}
                                                    `} >
                                              <span className="question-dropdown-block">
                                                <span className="question-name pl-1 d-flex">{Parser(questionAnswer.text)}</span>
                                              </span>
                                            </span>
                                          </div>
                                        </label>
                                        {currentAnswerAttempt && currentAnswerAttempt.specificFeedback
                                          ? <div className="question-feedback-container light-blue-background">
                                            <span className="form-check-label blue-color">
                                              <span className="question-dropdown-block">
                                                {Parser(currentAnswerAttempt.specificFeedback)}
                                              </span>
                                            </span>
                                          </div>
                                          : null}
                                      </div>
                                    });
                                  case 1: // Matching
                                    return <Auxiliary>
                                      {
                                        question.matchingQuestionAnswers && question.matchingQuestionAnswers.map(matchingQuestionAnswer => {
                                          const questionAnswers = [...question.questionAnswers];
                                          // questionAnswers.forEach(questionAnswer => {
                                          //   questionAnswer.text = this.convertHtmeToString(questionAnswer.text);
                                          //   questionAnswer.question = this.convertHtmeToString(questionAnswer.question);
                                          // })
                                          const currentAnswerAttempt = attemptAnswers && attemptAnswers.find(data => data.answerId === matchingQuestionAnswer.id);
                                          const currentMatchingQuestion = matchingAnswers && matchingAnswers.find(data => data.question === matchingQuestionAnswer.id);

                                          return <div key={matchingQuestionAnswer.id} className="question-body-container">
                                            <div className="row mb-4 mt-2">
                                              <div className={`col-md-10`}>
                                                <div className="row">
                                                  <div className="col-md-6 col-12">
                                                    <fieldset>
                                                      <legend>{translationService.translate("TR_QUESTION")}</legend>
                                                      <div className={`question-label-wrapper m-0 flex-column
                                                        ${currentAnswerAttempt && currentAnswerAttempt.isCorrect !== null
                                                          ? currentAnswerAttempt.isCorrect
                                                            ? "light-green-background"
                                                            : "light-red-background"
                                                          : "light-gray-background"}
                                                            ${isInvalidSubmit && invalidQuestionId === question.id && !currentMatchingQuestion ? "fail" : ""}`}>
                                                        <span className={`word-break-break-word
                                                            ${currentAnswerAttempt && currentAnswerAttempt.isCorrect !== null
                                                            ? currentAnswerAttempt.isCorrect
                                                              ? "green-color"
                                                              : "red-color"
                                                            : "gray-color"}
                                                            ${isInvalidSubmit && invalidQuestionId === question.id && !currentMatchingQuestion ? "fail" : ""}
                                                            `}

                                                        >
                                                          {/* {matchingQuestionAnswer.question} */}
                                                          {/* Question */}
                                                          <span className="question-dropdown-block">
                                                            {Parser(matchingQuestionAnswer.question)}
                                                          </span>
                                                        </span>


                                                      </div>
                                                    </fieldset>
                                                  </div>
                                                  <div className="col-md-6 col-12">
                                                    <fieldset className="min-width-auto">
                                                      <legend>{translationService.translate("TR_ANSWER")}</legend>
                                                      <div className="question-label-wrapper m-0 flex-column">
                                                        {
                                                          questionAnswers.map(questionAnswer => {
                                                            var a = (currentMatchingQuestion && currentMatchingQuestion.answer) || (currentAnswerAttempt
                                                              && (
                                                                currentAnswerAttempt.wrongAnswerId ?
                                                                  currentAnswerAttempt.wrongAnswerId :
                                                                  currentAnswerAttempt.answerId
                                                              ))
                                                            if (a === questionAnswer.id) {
                                                              return <Auxiliary key={questionAnswer.id}>
                                                                <span className="question-dropdown-block">
                                                                  {Parser(questionAnswer.text)}
                                                                </span>
                                                              </Auxiliary>
                                                            }
                                                          })
                                                        }
                                                      </div>
                                                    </fieldset>
                                                  </div>
                                                </div>
                                                {currentAnswerAttempt && currentAnswerAttempt.specificFeedback
                                                  ? <div className="question-feedback-container light-blue-background">
                                                    <span className="form-check-label blue-color">
                                                      {/* {currentAnswerAttempt.specificFeedback} */}
                                                      <span className="question-dropdown-block">
                                                        {Parser(currentAnswerAttempt.specificFeedback)}
                                                      </span>
                                                    </span>
                                                  </div>
                                                  : null}
                                              </div>


                                              <div className="col-md-2 mt-3">
                                                <div className="w-100 d-flex justify-content-end">

                                                  {/* <SelectOption
                                                    defaultValue={`${translationService.translate("TR_CHOOSE")}...`}
                                                    name="matchingAnswers"
                                                    items={questionAnswers}
                                                    isDisabled={isDisabled}
                                                    // value={(currentMatchingQuestion && currentMatchingQuestion.answer)
                                                    //   || (currentAnswerAttempt
                                                    //     && (currentAnswerAttempt.isCorrect
                                                    //       ? currentAnswerAttempt.answerId
                                                    //       : currentAnswerAttempt.wrongAnswerId))}
                                                    value={(currentMatchingQuestion && currentMatchingQuestion.answer)
                                                      || (currentAnswerAttempt
                                                        && (
                                                          currentAnswerAttempt.wrongAnswerId ?
                                                            currentAnswerAttempt.wrongAnswerId :
                                                            currentAnswerAttempt.answerId
                                                        ))}
                                                    containerClasses="m-0"
                                                    selectClasses={`matching-answer w-100
                                                     ${isInvalidSubmit && invalidQuestionId === question.id && !currentMatchingQuestion
                                                        ? "is-invalid error-bordered" : ""}
                                                       `}
                                                    changed={(event) => !isDisabled && this.onMatchingChange(event, questionIndex, matchingQuestionAnswer)}
                                                  /> */}

                                                  <div className={`btn-group dropleft ${isDisabled ? "disabled" : ""}`} >
                                                    <button
                                                      className={`btn mindalay--btn-default mindalay--btn-small dropdown-toggle ${isInvalidSubmit && invalidQuestionId === question.id && !currentMatchingQuestion
                                                        ? "is-invalid error-bordered" : ""} `}
                                                      type="button"
                                                      id="dropdownMenuButton"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      {`${translationService.translate("TR_CHOOSE")}...`}
                                                    </button>
                                                    <div
                                                      className="dropdown-menu box-shadow-33 question-answer-dropdown"
                                                      aria-labelledby="dropdownMenuButton"
                                                    // style={{ maxWidth: `${this.windowWidth}px` }}
                                                    >
                                                      {
                                                        questionAnswers.map((questionAnswer, index) => {
                                                          return <div className="answer" key={index}>
                                                            <Link
                                                              to="#"
                                                              className="dropdown-item"
                                                              onClick={() => {
                                                                this.onMatchingChange(questionAnswer.id, "matchingAnswers", questionIndex, matchingQuestionAnswer)
                                                              }}>
                                                              <span className="question-dropdown-block">
                                                                {Parser(questionAnswer.text)}
                                                              </span>
                                                            </Link>
                                                            <hr className="m-0" />
                                                          </div>
                                                        })
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        })
                                      }
                                    </Auxiliary>;
                                  case 2: // Short
                                    const hasAttemptShortAnswer = attemptAnswers && attemptAnswers[0];
                                    return <div className="question-body-container">
                                      <div className={`question-label-wrapper
                                        ${hasAttemptShortAnswer && attemptAnswers[0].isCorrect !== null
                                          ? attemptAnswers[0].isCorrect
                                            ? "light-green-background"
                                            : "light-red-background"
                                          : "light-gray-background"}`}>
                                        <p className={`mr-2 ${hasAttemptShortAnswer && attemptAnswers[0].isCorrect !== null
                                          ? attemptAnswers[0].isCorrect
                                            ? "green-color"
                                            : "red-color"
                                          : "gray-color"}`}
                                        >
                                          {translationService.translate("TR_ANSWER")}:
                                        </p>
                                        <Textarea
                                          type="text"
                                          id="shortAnswer"
                                          name="shortAnswer"
                                          blockClassName="mb-0 w-100"
                                          textAreaClassName="pr--5"
                                          rows="3"
                                          max={answerMaxLength}
                                          placeholder={`${translationService.translate("TR_TYPE")}...`}
                                          value={(attemptAnswers && attemptAnswers[0] && attemptAnswers[0].shortAnswer) || shortAnswer}
                                          disabled={isDisabled}
                                          isInvalidSubmit={isInvalidSubmit && invalidQuestionId === question.id}
                                          onChange={event => this.onShortAnswerChange(event, questionIndex, answerMaxLength)}
                                          failedFields={failedFields}
                                        />
                                      </div>
                                      {attemptAnswers && attemptAnswers[0] && attemptAnswers[0].specificFeedback
                                        ? <div className="question-feedback-container light-blue-background">
                                          <p className="form-check-label blue-color">
                                            {/* {attemptAnswers[0].specificFeedback} */}
                                            {Parser(attemptAnswers[0].specificFeedback)}
                                          </p>
                                        </div>
                                        : null}
                                    </div>;
                                  case 3: // True-False
                                    const hasAttemptTrueFalseAnswer = attemptAnswers && attemptAnswers[0];
                                    return <Auxiliary>
                                      <div className="question-body-container">
                                        <div className={`question-label-wrapper ${(hasAttemptTrueFalseAnswer
                                          && attemptAnswers[0].trueFalse === true
                                          && attemptAnswers[0].isCorrect !== null)
                                          ? attemptAnswers[0].isCorrect
                                            ? "light-green-background"
                                            : "light-red-background"
                                          : "light-gray-background"}`}>
                                          <InputRadio
                                            id="trueVal"
                                            name="isCorrect"
                                            value={true}
                                            disabled={isDisabled}
                                            checked={isCorrect === true || (attemptAnswers && attemptAnswers[0] && attemptAnswers[0].trueFalse === true)}
                                            labelValue={translationService.translate('TR_TRUE')}
                                            labelClassName={isInvalidSubmit && invalidQuestionId === question.id && isCorrect === null ? "is-invalid fail" : ""}
                                            radioClassName={isInvalidSubmit && invalidQuestionId === question.id && isCorrect === null ? "is-invalid fail" : ""}
                                            blockClassName="mb-1 mr-3"
                                            onChange={event => !isDisabled && this.onRadioChange(event, questionIndex, BOOLEAN_KEY)}
                                          />
                                        </div>
                                        {attemptAnswers && attemptAnswers[0] && attemptAnswers[0].trueFalse === true && attemptAnswers[0].specificFeedback
                                          ? <div className="question-feedback-container light-blue-background">
                                            <p className="form-check-label blue-color">
                                              {/* {attemptAnswers[0].specificFeedback} */}
                                              {Parser(attemptAnswers[0].specificFeedback)}
                                            </p>
                                          </div>
                                          : null}
                                      </div>
                                      <div className="question-body-container">
                                        <div className={`question-label-wrapper ${(hasAttemptTrueFalseAnswer
                                          && attemptAnswers[0].trueFalse === false
                                          && attemptAnswers[0].isCorrect !== null)
                                          ? attemptAnswers[0].isCorrect
                                            ? "light-green-background"
                                            : "light-red-background"
                                          : "light-gray-background"}`}>
                                          <InputRadio
                                            id="falseVal"
                                            name="isCorrect"
                                            value={false}
                                            disabled={isDisabled}
                                            checked={isCorrect === false || (attemptAnswers && attemptAnswers[0] && attemptAnswers[0].trueFalse === false)}
                                            labelClassName={isInvalidSubmit && invalidQuestionId === question.id && isCorrect === null ? "is-invalid fail" : ""}
                                            radioClassName={isInvalidSubmit && invalidQuestionId === question.id && isCorrect === null ? "is-invalid fail" : ""}
                                            labelValue={translationService.translate('TR_FALSE')}
                                            onChange={event => !isDisabled && this.onRadioChange(event, questionIndex, BOOLEAN_KEY)}
                                          />
                                        </div>
                                        {attemptAnswers && attemptAnswers[0] && attemptAnswers[0].trueFalse === false && attemptAnswers[0].specificFeedback
                                          ? <div className="question-feedback-container light-blue-background">
                                            <p className="form-check-label blue-color">
                                              {/* {attemptAnswers[0].specificFeedback} */}
                                              {Parser(attemptAnswers[0].specificFeedback)}
                                            </p>
                                          </div>
                                          : null}
                                      </div>
                                    </Auxiliary>;
                                  default:
                                    break
                                }
                              })()
                            }
                            {
                              (() => {
                                let fieldName = '';
                                switch (question.questionType) {
                                  case 0: //Multi
                                    fieldName = question.isMultiAnswer ? "answerIds" : "answerId";
                                    break;
                                  case 1: //Matching
                                    fieldName = "matchingAnswers";
                                    break;
                                  case 2: //Short
                                    fieldName = "shortAnswer";
                                    break;
                                  case 3: //TrueFalse
                                    fieldName = "isCorrect";
                                    break;
                                  default:
                                    break
                                }
                                const upperCaseFieldName = fieldName && fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
                                return <div className="col-12">
                                  {
                                    failedFields && failedFields.hasOwnProperty(upperCaseFieldName)
                                      ? <small className="fail">{question[upperCaseFieldName]}</small>
                                      : null
                                  }
                                  <div className="text-right mt-4">
                                    {
                                      !isDisabled && canBeChecked && !reviewAndSubmit
                                        ? <ActionButton
                                          spinnerId={`attemptCheck_${questionIndex}`}
                                          type="button"
                                          name={translationService.translate("TR_CHECK")}
                                          className={`mindalay--btn-default ${tryAgain ? "disabled" : ""}`}
                                          clicked={() => this.check(questionIndex, fieldName)}
                                        /> : null
                                    }
                                    {
                                      canTryAgain && !reviewAndSubmit
                                        ? <button type="button" className="mindalay--btn-default" onClick={() => this.questionTryAgain(questionIndex)}>
                                          {translationService.translate("TR_TRY_AGAIN")}
                                        </button> : null
                                    }
                                  </div>
                                </div>
                              })()
                            }
                          </div>
                        </div>
                      </form>
                      {
                        isAttemptFinished || isReview
                          ? null
                          : <Auxiliary>
                            <hr />
                            <div className="content-header-btn-group d-sm-flex d-block justify-content-between align-items-center">

                              <div className="pagination-location ml-2 d-none d-sm-block">
                                <span>{currentQuestionIndex + 1}</span>
                                <span>/</span>
                                <span>{questionsInfo.length}</span>
                              </div>

                              <div className="pagination-group d-flex justify-content-center my-2">
                                <ReactPaginate
                                  nextLabel={translationService.translate("TR_NEXT")}
                                  onPageChange={this.handlePageClick}
                                  pageRangeDisplayed={2}
                                  marginPagesDisplayed={2}
                                  pageCount={questionsInfo.length}
                                  previousLabel={translationService.translate("TR_PREVIOUS")}
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  renderOnZeroPageCount={null}
                                  forcePage={currentQuestionIndex}
                                />
                              </div>

                              {/* <div className="pagination-group d-flex justify-content-center my-2">
                                <button
                                  type="button"
                                  className="mindalay--btn-small mindalay--btn-default d-flex ml-2 align-items-center left"
                                  disabled={!currentQuestionIndex}
                                  onClick={() => this.openPrevQuestion(currentQuestionIndex)}>
                                  <DoubleArrowSvg />
                                </button>
                                {
                                  currentQuestionIndex - 1 > 1 ? <span className=" ml-2">...</span> : null
                                }
                                {
                                  questionsInfo.map((paginationQI, paginationQIIndex) => {
                                    const maxPageNumber = currentQuestionIndex + 2;
                                    const minPageNumber = currentQuestionIndex - 2;
                                    if (paginationQIIndex > maxPageNumber || paginationQIIndex < minPageNumber) { return false; }
                                    return <button
                                      key={paginationQI.id}
                                      type="button"
                                      className={`${currentQuestionIndex === paginationQIIndex ? "active-pagination-item" : ""}
                                        ${(questionInfo.id === paginationQI.id && passed) || paginationQI.passed ? "passed-pagination-item" : ''}
                                        mindalay--btn-small mindalay--btn-default d-flex ml-2 align-items-center`}
                                      onClick={() => this.openCurrentQuestion(paginationQIIndex)}
                                    >
                                      {paginationQIIndex + 1}
                                    </button>
                                  })
                                }
                                {
                                  currentQuestionIndex + 2 < questionsInfo.length - 1 ? <span className=" ml-2">...</span> : null
                                }
                                <button
                                  type="button"
                                  className="mindalay--btn-small mindalay--btn-default d-flex ml-2 align-items-center"
                                  disabled={currentQuestionIndex === questionsInfo.length - 1}
                                  onClick={() => this.openNextQuestion(currentQuestionIndex)}
                                >
                                  <DoubleArrowSvg />
                                </button>
                              </div> */}

                              <div className="d-flex justify-content-between align-items-center">
                                <div className="pagination-location ml-2 d-block d-sm-none">
                                  <span>{currentQuestionIndex + 1}</span>
                                  <span>/</span>
                                  <span>{questionsInfo.length}</span>
                                </div>

                                <div className="pagination-dropdown pt-2">
                                  <DataListOption
                                    id="paginationdropdown"
                                    type="text"
                                    containerClasses="m-0"
                                    inputClasses="form-control-padding"
                                    currentPage={currentQuestionIndex + 1}
                                    items={questionsInfo}
                                    changed={(value) => {
                                      this.openCurrentQuestion(+value - 1)
                                    }}
                                  />
                                </div>
                              </div>


                            </div>
                          </Auxiliary>
                      }
                      {
                        whetherCorrect || hint || generalFeedback
                          ? <div className="question-form">
                            <hr />
                            <div className="question-body-container">
                              {
                                whetherCorrect ? <div className="question-label-wrapper light-blue-background blue-border mt-3">
                                  {/* <p className="blue-text-color word-break-break-word">{whetherCorrect}</p> */}
                                  <span className="question-dropdown-block">
                                    <span className="blue-text-color word-break-break-word"> {Parser(whetherCorrect)}</span>
                                  </span>
                                </div> : null
                              }
                              {
                                hint ? <div className="question-label-wrapper light-blue-background blue-border mt-3">
                                  {/* <p className="blue-text-color word-break-break-word">{hint}</p> */}
                                  <span className="question-dropdown-block">
                                    <span className="blue-text-color word-break-break-word">{Parser(hint)}</span>
                                  </span>
                                </div> : null
                              }
                              {
                                generalFeedback ? <div className="question-label-wrapper light-blue-background blue-border mt-3">
                                  {/* <p className="blue-text-color word-break-break-word">{generalFeedback}</p> */}
                                  <span className="question-dropdown-block">
                                    <span className="blue-text-color word-break-break-word">{Parser(generalFeedback)}</span>
                                  </span>
                                </div> : null
                              }
                            </div>
                          </div> : null
                      }
                    </div>
                    {
                      (isReview || reviewAndSubmit) && questionInfoIndex !== questionsInfo.length - 1 ? <hr /> : null
                    }
                  </Auxiliary>
                })
              }
              <hr />
              <div className="content-body pt-1 d-flex justify-content-end">
                {
                  !isAttemptFinished && !isQuizOverdue && !reviewAndSubmit
                    ? <ActionButton
                      type="button"
                      name={translationService.translate("TR_REVIEW_AND_SUBMIT")}
                      className="mindalay--btn-secondary mt-2"
                      clicked={() => this.showReviewAndSubmit(quizData)}
                    />
                    : !isAttemptFinished && !isQuizOverdue
                      ? <ActionButton
                        type="button"
                        name={translationService.translate("TR_CANCEL")}
                        className="mindalay--btn-default mr-2 mt-2"
                        clicked={this.cancelReviewAndSubmit}
                      /> : null
                }
                {
                  (isQuizOverdue || reviewAndSubmit) && !isAttemptFinished ? <ActionButton
                    type="button"
                    spinnerId={spinnerId}
                    name={translationService.translate("TR_FINISH_ATTEMPT")}
                    className={`mindalay--btn-secondary mt-2`}
                    clicked={this.onSubmit}
                  /> : null
                }
                {
                  isAttemptFinished ? <button type="button" className="btn mindalay--btn-default" onClick={this.goBack}>
                    {translationService.translate('TR_GOBACK')}
                  </button> : null
                }
              </div>
            </div>
          </div>
        </div>
      </div >
      : null;
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language,
  languages: state.language.languages,
  pureLayoutBackUrl: state.main.pureLayoutBackUrl,
  user: state.user.user
});

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  addButtonSpinner,
  removeButtonSpinner
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(QuizAttempt);
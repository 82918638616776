import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import {
	BOOLEAN_KEY,
	CURRENCY_SYMBOL_KEY,
	DEFAULT_CURRENCY_SYMBOL_KEY,
	ERROR_KEY,
	NUMBER_KEY,
	SUCCESS_KEY,
	TR_YES,
} from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import NodataImage from "./../../assets/images/illustrations/nodata.svg";
import { COURSES_KEY, ORGANIZATION_ADMIN_KEY } from "../../Constants/urlKeys";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import Input from "../../Components/Inputs/input";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import ActionButton from "../../Components/ActionButton/actionButton";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ShowMoreText from "react-show-more-text";
import Parser from "html-react-parser";
import { TR_DATA_SAVED_KEY } from "../../Constants/translationKeys";
import InfoSvg from "./../../Components/Svg/infoSvg";

const buttonSpinnerId = uuid();
const CoursePriceForm = props => {
	const gracePeriodEntities = [
		{
			label: "TR_DAYS",
			value: 3,
		},
		{
			label: "TR_WEEKS",
			value: 4,
		},
		{
			label: "TR_MONTS",
			value: 5,
		},
	];

	const periodEntities = [
		{
			label: "TR_DAYS",
			value: 3,
		},
		{
			label: "TR_WEEKS",
			value: 4,
		},
		{
			label: "TR_MONTS",
			value: 5,
		},
	];

	const paymentActions = [
		{
			label: "TR_NO_ACTION",
			value: 0,
		},
		{
			label: "TR_SEND_NOTIFICATION_ONLY",
			value: 1,
		},
		{
			label: "TR_SEND_NOTIFICATION_AND_RESTRICT_ACCESS",
			value: 2,
		},
	];

	const amountMaxValue = 1000000;
	const gracePeriodMaxValue = 1000;
	const periodMaxValue = 1000;
	const gracePeriodMinValue = 2;
	const periodMinValue = 2;
	const paymentCountMaxValue = 100;
	const paymentCountMinValue = 1;
	const currencySymbol = localStorage.getItem(CURRENCY_SYMBOL_KEY)
		? localStorage.getItem(CURRENCY_SYMBOL_KEY)
		: DEFAULT_CURRENCY_SYMBOL_KEY;
	const { courseId } = props.match.params;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [currentCourse, setCurrentCourse] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [allowPeriodicPayment, setAllowPeriodicPayment] = useState(false);

	const [id, setId] = useState(null);
	const [amount, setAmount] = useState(1);
	const [gracePeriodEntity, setGracePeriodEntity] = useState(3);
	const [gracePeriod, setGracePeriod] = useState(0);
	const [isShowGracePeriod, setIsShowGracePeriod] = useState(false);
	const [paymentCount, setPaymentCount] = useState(1);
	const [periodEntity, setPeriodEntity] = useState(3);
	const [period, setPeriod] = useState(1);
	const [nonPaymentAction, setNonPaymentAction] = useState(0);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getCourseById();
		getInnerPaymentAsAdmin();
	}, []);

	const getCourseById = () => {
		if (!courseId) {
			return false;
		}
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getCourseByIdFromOrgAdmin(courseId)
			.then(response => {
				if (response && response.data) {
					setCurrentCourse(response.data);
				}
				extractPartialViewSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getInnerPaymentAsAdmin = () => {
		if (!courseId) {
			return false;
		}
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getInnerPaymentAsAdmin(courseId)
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					data.id && setId(data.id);
					setAmount(data.amount ? data.amount : 1);
					setGracePeriodEntity(
						data.gracePeriodEntity ? data.gracePeriodEntity : 3,
					);
					setGracePeriod(data.gracePeriod ? data.gracePeriod : 0);
					setPeriodEntity(data.periodEntity ? data.periodEntity : 3);
					setPeriod(data.period ? data.period : 1);
					setNonPaymentAction(
						data.nonPaymentAction ? data.nonPaymentAction : 0,
					);
					setPaymentCount(data.paymentCount ? data.paymentCount : 1);
					if (data.paymentCount && data.paymentCount > 1) {
						setAllowPeriodicPayment(true);
					}
					if (data.nonPaymentAction === 2) {
						setIsShowGracePeriod(true);
					}
				}
				extractPartialViewSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const onNumberChange = (event, cb, maxValue = null) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				+event.target.value >= 0 &&
				+event.target.value <= maxValue)
		) {
			cb(event.target.value);
		}
	};

	const onCheckboxChange = (event, cb) => {
		if (typeof event.target.checked === BOOLEAN_KEY) {
			cb(event.target.checked);
			if (!event.target.checked) {
				setPeriodEntity(periodEntity ? periodEntity : 3);
				setPeriod(period ? period : 0);
				setPaymentCount(paymentCount ? paymentCount : 1);
				setNonPaymentAction(nonPaymentAction ? nonPaymentAction : 0);
			}
		}
	};

	const onSubmit = event => {
		event && event.preventDefault();
		const form = {
			amount: +amount,
			gracePeriodEntity,
			gracePeriod: +gracePeriod,
			paymentCount: +paymentCount,
			periodEntity,
			period: +period,
			nonPaymentAction,
			entityType: 0,
			entityId: +courseId,
		};
		if (!allowPeriodicPayment) {
			form.period = null;
			form.periodEntity = 3;
			form.paymentCount = 1;
		}
		setButtonSpinner(buttonSpinnerId);
		if (id) {
			form.id = id;
		}
		(id
			? ApiService.updateCoursePriceAsAdmin(form)
			: ApiService.createCoursePriceAsAdmin(form)
		)
			.then(() => {
				if (!allowPeriodicPayment) {
					setPeriod(1);
					setNonPaymentAction(0);
					setPeriodEntity(3);
					setPaymentCount(1);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate(TR_DATA_SAVED_KEY),
				);
				extractButtonSpinner(buttonSpinnerId);
				window.history.back();
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const setPartialViewSpinner = useCallback(spinner => {
		dispatch(addPartialViewSpinner(spinner));
	}, []);

	const extractPartialViewSpinner = useCallback(spinner => {
		dispatch(removePartialViewSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPartialViewSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	return translationService ? (
		currentCourse ? (
			<div className="container mt-4">
				<div className="row">
					<div className="col-12">
						<h2 className="content-title p-0">
							<Link
								// to={`/${language}/${ORGANIZATION_ADMIN_KEY}/${COURSES_KEY}`}
								to={`#`}
								title={translationService.translate("TR_BACK")}
								onClick={() => window.history.back()}>
								<ArrowBackSvg />
							</Link>
							{currentCourse.name
								? currentCourse.name
								: translationService.translate("TR_COURSE")}
						</h2>
						<hr />
					</div>
					{currentCourse && currentCourse.description ? (
						<div className="col-12">
							<Auxiliary>
								<div className="course-view-description">
									<h3 className="title">
										{translationService.translate("TR_DESCRIPTION")}
									</h3>
									<div>
										<ShowMoreText
											lines={3}
											more={translationService.translate("TR_SHOW_MORE")}
											less={translationService.translate("TR_LESS")}
											className="content-css"
											anchorClass="my-anchor-css-class"
											expanded={false}
											truncatedEndingComponent={"... "}>
											<span className="course-description-block">
												{Parser(currentCourse.description)}
											</span>
										</ShowMoreText>
									</div>
								</div>
							</Auxiliary>
							<hr />
						</div>
					) : null}
				</div>
				<form className="px-2" onSubmit={onSubmit}>
					<div className="row">
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<div className="icon-input">
										<Input
											type="number"
											id="amount"
											name="amount"
											value={amount}
											isInvalidSubmit={isInvalidSubmit}
											labelValue={`${translationService.translate(
												"TR_PRICE",
											)} *`}
											onChange={event =>
												onNumberChange(event, setAmount, amountMaxValue)
											}
											onBlur={() => {
												if (!amount || (amount && amount < 1)) {
													setAmount(1);
												}
											}}
										/>
										{currencySymbol ? <span>{currencySymbol}</span> : null}
									</div>
								</div>
								{paymentActions && paymentActions.length ? (
									<div className="col-md-6">
										<label>
											{translationService.translate("TR_NON_PAYMENT_ACTION")}
										</label>
										<ReactSelectOption
											value={nonPaymentAction}
											selectedValue={(() => {
												const selectedValue = {
													...paymentActions.find(
														data => data.value === nonPaymentAction,
													),
												};
												if (selectedValue) {
													selectedValue.label = translationService.translate(
														selectedValue.label,
													);
												}
												return selectedValue;
											})()}
											items={paymentActions.map(data => ({
												label: translationService.translate(data.label),
												value: data.value,
											}))}
											onChange={item => {
												setGracePeriodEntity(3);
												setGracePeriod(0);
												setNonPaymentAction(item.value);
												setIsShowGracePeriod(false);
											}}
										/>
									</div>
								) : null}
							</div>
							{nonPaymentAction === 2 ? (
								<div className="row">
									<div className="col-12">
										<InputCheckBox
											id="isGracePeriod"
											name="isgracePeriod"
											checked={isShowGracePeriod}
											labelValue={translationService.translate(
												"TR_SHOW_GRACE_PERIOD",
											)}
											blockClassName="mb-3"
											onChange={event => {
												setIsShowGracePeriod(event.target.checked);
												if (!event.target.checked) {
													setGracePeriod(0);
													setGracePeriodEntity(3);
												}
											}}
										/>
									</div>
									{isShowGracePeriod && (
										<>
											<div className="col-md-6 mb-4">
												<label>
													{translationService.translate("TR_GRACE_PERIOD_TYPE")}
												</label>
												{/* <label>
                      {`${translationService.translate(
											"TR_GRACE_PERIOD_ENTITY",
										)}`}
                    </label> */}
												<ReactSelectOption
													value={gracePeriodEntity}
													selectedValue={(() => {
														const selectedValue = {
															...gracePeriodEntities.find(
																data => data.value === gracePeriodEntity,
															),
														};
														if (selectedValue) {
															selectedValue.label =
																translationService.translate(
																	selectedValue.label,
																);
															// selectedValue.value = selectedValue.value;
														}
														return selectedValue;
													})()}
													items={gracePeriodEntities.map(data => ({
														label: translationService.translate(data.label),
														value: data.value,
													}))}
													onChange={item => setGracePeriodEntity(item.value)}
												/>
											</div>
											<div className="col-md-6">
												<Input
													type="number"
													id="gracePeriod"
													name="gracePeriod"
													value={gracePeriod}
													isInvalidSubmit={isInvalidSubmit}
													labelValue={`${translationService.translate(
														"TR_GRACE_PERIOD",
													)} *`}
													onChange={event =>
														onNumberChange(
															event,
															setGracePeriod,
															gracePeriodMaxValue,
														)
													}
													// onBlur={() => {
													//   if (!gracePeriod || (gracePeriod && gracePeriod < gracePeriodMinValue)) {
													//     setGracePeriod(2);
													//   }
													// }}
												/>
											</div>
										</>
									)}
								</div>
							) : null}
							<div className="row">
								<div className="col-12">
									<InputCheckBox
										id="allowPeriodicPayment"
										name="allowPeriodicPayment"
										checked={allowPeriodicPayment}
										labelValue={translationService.translate(
											"TR_ALLOW_PERIODIC_PAYMENT",
										)}
										blockClassName="mb-3"
										onChange={event =>
											onCheckboxChange(event, setAllowPeriodicPayment)
										}
									/>
								</div>
							</div>
							{allowPeriodicPayment ? (
								<div className="row">
									<div className="col-md-6 mb-4">
										<label>
											{translationService.translate("TR_PERIOD_TYPE")}
										</label>
										{/* <label>{`${translationService.translate(
											"TR_PERIOD_ENTITY",
										)}`}</label> */}
										<div className="info-title d-inline-block">
											<div>
												<InfoSvg />
												<span style={{ display: "none" }}>
													{translationService.translate(
														"TR_PERIOD_ENTITY_INFO",
													)}
												</span>
											</div>
										</div>
										<ReactSelectOption
											value={periodEntity}
											selectedValue={(() => {
												const selectedValue = {
													...periodEntities.find(
														data => data.value === periodEntity,
													),
												};
												if (selectedValue) {
													selectedValue.label = translationService.translate(
														selectedValue.label,
													);
												}
												return selectedValue;
											})()}
											items={periodEntities.map(data => ({
												label: translationService.translate(data.label),
												value: data.value,
											}))}
											onChange={item => setPeriodEntity(item.value)}
										/>
									</div>
									<div className="col-md-6">
										<Input
											type="number"
											id="period"
											name="period"
											value={period}
											isInvalidSubmit={isInvalidSubmit}
											labelValue={`${translationService.translate(
												"TR_PERIOD",
											)} *`}
											onChange={event =>
												onNumberChange(event, setPeriod, periodMaxValue)
											}
											onBlur={() => {
												if (!period || (period && period < periodMinValue)) {
													setPeriod(1);
												}
											}}
										/>
									</div>
									<div className="col-md-6">
										<Input
											type="number"
											id="paymentCount"
											name="paymentCount"
											value={paymentCount}
											isInvalidSubmit={isInvalidSubmit}
											labelValue={`${translationService.translate(
												"TR_PAYMENT_COUNT",
											)} *`}
											onChange={event =>
												onNumberChange(
													event,
													setPaymentCount,
													paymentCountMaxValue,
												)
											}
											onBlur={() => {
												if (
													!paymentCount ||
													(paymentCount && paymentCount < paymentCountMinValue)
												) {
													setPaymentCount(1);
												}
											}}
										/>
									</div>
								</div>
							) : null}
						</div>
						<div className="col-12">
							<hr />
						</div>
						<div className="col-12 my-3">
							<ActionButton
								spinnerId={buttonSpinnerId}
								type="submit"
								className="mindalay--btn-default float-right"
								name={translationService.translate(
									id ? "TR_UPDATE" : "TR_CREATE",
								)}
							/>
						</div>
					</div>
				</form>
			</div>
		) : (
			<div className="no-data-container mt-3">
				<div className="no-data-wrapper">
					<p>{translationService.translate("TR_NO_DATA")}</p>
					<img src={NodataImage} alt="/" />
				</div>
			</div>
		)
	) : null;
};

export default withRouter(CoursePriceForm);

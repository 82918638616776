import React, { useEffect, useState } from "react";
import SubmitButton from "../../../Components/SubmitButton/submitButton";
import ActionButton from "../../../Components/ActionButton/actionButton";
import TranslationService from "../../../Services/translationService";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import {
	addButtonSpinner,
	removeButtonSpinner,
} from "../../../Store/Actions/spinner";
import { CirclePicker } from "react-color";
import MainService from "../../../Services/mainService";
import ApiService from "../../../Services/apiService";
import AlertService from "../../../Services/alertService";
import { ERROR_KEY, SUCCESS_KEY } from "../../../Constants/mainKeys";
import { setSettings } from "../../../Store/Actions/settings";

const spinnerId = uuid();

const ColorForm = () => {
	const dispatch = useDispatch();
	const { settings } = useSelector(state => state.settings);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [isChange, setIsChange] = useState(false);
	const [values, setValues] = useState({
		mainColor: "#32064a",
		secondaryColor: "#e56b1f",
		textColor: "#00266C",
		headerBackgroundColor: "#FFFFFF",
		bodyBackgroundColor: "#FFFFFF",
		footerBackgroundColor: "#F9F9F9",
	});

	useEffect(() => {
		if (settings) {
			const newValues = {};
			Object.keys(values).forEach(key => {
				if (settings[key]) {
					newValues[key] = settings[key];
				}
			});
			setValues(values => ({ ...values, ...newValues }));
		}
	}, [settings]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (values.mainColor) {
			document.documentElement.style.setProperty(
				"--mindalay--brand-color",
				values.mainColor,
			);
			document.documentElement.style.setProperty(
				"--mindalay--brand-color-darken-35",
				MainService.darkenColor(values.mainColor, 35),
			);
		}
	}, [values.mainColor]);

	useEffect(() => {
		if (values.headerBackgroundColor) {
			document.documentElement.style.setProperty(
				"--mindalay--header-color",
				values.headerBackgroundColor,
			);
		}
	}, [values.headerBackgroundColor]);

	useEffect(() => {
		if (values.bodyBackgroundColor) {
			document.documentElement.style.setProperty(
				"--mindalay--body-color",
				values.bodyBackgroundColor,
			);
		}
	}, [values.bodyBackgroundColor]);

	useEffect(() => {
		if (values.footerBackgroundColor) {
			document.documentElement.style.setProperty(
				"--mindalay--footer-color",
				values.footerBackgroundColor,
			);
		}
	}, [values.footerBackgroundColor]);

	useEffect(() => {
		if (values.secondaryColor) {
			document.documentElement.style.setProperty(
				"--mindalay--secondary-color",
				values.secondaryColor,
			);
			document.documentElement.style.setProperty(
				"--mindalay--secondary-color-lightened-50",
				MainService.lightenColor(values.secondaryColor, 50),
			);
			document.documentElement.style.setProperty(
				"--mindalay--secondary-color-lightened-minus-5",
				MainService.darkenColor(values.secondaryColor, 5),
			);
		}
	}, [values.secondaryColor]);

	const onChangeColor = (color, fieldName) => {
		setValues(values => ({ ...values, [fieldName]: color }));
		setIsChange(true);
	};

	const onSubmit = event => {
		event && event.preventDefault();
		dispatch(addButtonSpinner(spinnerId));
		ApiService.updateSettingsWhiteLabelColors(values)
			.then(res => {
				if (res.data) {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_SAVED"),
					);
					dispatch(setSettings(res.data));
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removeButtonSpinner(spinnerId));
				setIsChange(false);
			});
	};

	return translationService ? (
		<div className="mt-4">
			<form onSubmit={onSubmit} onChange={() => setIsChange(true)}>
				<div className="row mb-3 w-100">
					<div className="col-12">
						<div className="row">
							<div className="col-md-6 mt-4">
								<div className={`form-group mb-0`}>
									<div className="d-flex align-items-center flex-wrap mb-2">
										<label className="mb-2 mr-3">
											{translationService.translate("TR_HEADER_COLOR")}
										</label>
										<div
											className="border rounded "
											style={{
												width: "100px",
												height: "30px",
												backgroundColor: values.headerBackgroundColor,
											}}
										/>
									</div>
									<div className="bg-white d-flex align-items-center border rounded p-3">
										<CirclePicker
											circleSize={45}
											className="w-auto custom-circle-picker"
											colors={[
												"#ffffff",
												"#f0f3e4",
												"#f1eae3",
												"#f2f1e4",
												"#ebe6f0",
												"#f0f4e9",
												"#eef0e7",
												"#e8eaf2",
											]}
											onChangeComplete={color => {
												onChangeColor(color.hex, "headerBackgroundColor");
											}}
										/>
										<div className="mindalay--color-picker w-auto">
											<div
												className="color-picker"
												onClick={() => {
													document.getElementById("headerBackgroundColor") &&
														document
															.getElementById("headerBackgroundColor")
															.click();
												}}>
												<input
													id="headerBackgroundColor"
													type="color"
													className="ml-3"
													value={values.headerBackgroundColor}
													style={{ border: "1px solid #ffffff" }}
													onChange={e => {
														onChangeColor(
															e.target.value,
															"headerBackgroundColor",
														);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-6 mt-4">
								<div className={`form-group mb-0`}>
									<div className="d-flex align-items-center flex-wrap mb-2">
										<label className="mb-2 mr-3">
											{translationService.translate("TR_BODY_COLOR")}
										</label>
										<div
											className="border rounded "
											style={{
												width: "100px",
												height: "30px",
												backgroundColor: values.bodyBackgroundColor,
											}}
										/>
									</div>
									<div className="bg-white d-flex align-items-center border rounded p-3">
										<CirclePicker
											circleSize={45}
											className="w-auto custom-circle-picker"
											colors={[
												"#ffffff",
												"#f1f2e7",
												"#f3edeb",
												"#f0f0e6",
												"#f4efe8",
												"#f1e7f4",
												"#eaf0f1",
												"#f4f2e5",
											]}
											onChangeComplete={color => {
												onChangeColor(color.hex, "bodyBackgroundColor");
											}}
										/>
										<div className="mindalay--color-picker w-auto">
											<div
												className="color-picker"
												onClick={() => {
													document.getElementById("bodyBackgroundColor") &&
														document
															.getElementById("bodyBackgroundColor")
															.click();
												}}>
												<input
													id="bodyBackgroundColor"
													type="color"
													className="ml-3"
													value={values.bodyBackgroundColor}
													style={{ border: "1px solid #ffffff" }}
													onChange={e => {
														onChangeColor(
															e.target.value,
															"bodyBackgroundColor",
														);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-6 mt-4">
								<div className={`form-group mb-0`}>
									<div className="d-flex align-items-center flex-wrap mb-2">
										<label className="mb-2 mr-3">
											Choose "Footer" background color
										</label>
										<div
											className="border rounded "
											style={{
												width: "100px",
												height: "30px",
												backgroundColor: values.footerBackgroundColor,
											}}
										/>
									</div>
									<div className="bg-white d-flex align-items-center border rounded p-3">
										<CirclePicker
											circleSize={45}
											className="w-auto custom-circle-picker"
											colors={[
												"#ffffff",
												"#f2eaf0",
												"#f2f0e3",
												"#eef1e9",
												"#f0e9f0",
												"#eaf1e6",
												"#eaf1ed",
												"#f0e9e7",
											]}
											onChangeComplete={color => {
												onChangeColor(color.hex, "footerBackgroundColor");
											}}
										/>
										<div className="mindalay--color-picker w-auto">
											<div
												className="color-picker"
												onClick={() => {
													document.getElementById("footerBackgroundColor") &&
														document
															.getElementById("footerBackgroundColor")
															.click();
												}}>
												<input
													id="footerBackgroundColor"
													type="color"
													className="ml-3"
													value={values.footerBackgroundColor}
													style={{ border: "1px solid #ffffff" }}
													onChange={e => {
														onChangeColor(
															e.target.value,
															"footerBackgroundColor",
														);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-12">
						<div className="row">
							<div className="col-md-6 mt-4">
								<div className={`form-group mb-0`}>
									<div className="d-flex align-items-center flex-wrap mb-2">
										<label className="mb-2 mr-3">
											{translationService.translate("TR_MENU_COLOR")}
										</label>
										<div
											className="border rounded "
											style={{
												width: "100px",
												height: "30px",
												backgroundColor: values.mainColor,
											}}
										/>
									</div>

									<div className="d-flex align-items-center border p-3 rounded">
										<CirclePicker
											circleSize={45}
											className="w-auto custom-circle-picker"
											colors={[
												"#32064a",
												"#51a99b",
												"#a26f1d",
												"#d95f1b",
												"#3358a2",
												"#c326a3",
												"#3d5a0b",
												"#9c325d",
											]}
											onChangeComplete={color => {
												onChangeColor(color.hex, "mainColor");
											}}
										/>
										<div className="mindalay--color-picker w-auto">
											<div
												className="color-picker"
												onClick={() => {
													document.getElementById("main-color") &&
														document.getElementById("main-color").click();
												}}>
												<input
													id="main-color"
													type="color"
													className="ml-3"
													value={values.mainColor}
													style={{ border: "1px solid #ffffff" }}
													onChange={e => {
														onChangeColor(e.target.value, "mainColor");
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 mt-4">
								<div className={`form-group mb-0`}>
									<div className="d-flex align-items-center flex-wrap mb-2">
										<label className="mb-2 mr-3">
											{translationService.translate("TR_ADDITIONAL_COLOR")}
										</label>
										<div
											className="border rounded "
											style={{
												width: "100px",
												height: "30px",
												backgroundColor: values.secondaryColor,
											}}
										/>
									</div>
									<div className="bg-white d-flex align-items-center border rounded p-3">
										<CirclePicker
											circleSize={45}
											className="w-auto custom-circle-picker"
											colors={[
												"#e56b1f",
												"#66cccc",
												"#cc99ff",
												"#99cc99",
												"#ff99cc",
												"#cccc66",
												"#66cc99",
												"#cc66cc",
											]}
											onChangeComplete={color => {
												onChangeColor(color.hex, "secondaryColor");
											}}
										/>
										<div className="mindalay--color-picker w-auto">
											<div
												className="color-picker"
												onClick={() => {
													document.getElementById("secondary-color") &&
														document.getElementById("secondary-color").click();
												}}>
												<input
													id="secondary-color"
													type="color"
													className="ml-3"
													value={values.secondaryColor}
													style={{ border: "1px solid #ffffff" }}
													onChange={e => {
														onChangeColor(e.target.value, "secondaryColor");
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 mt-4">
								<div className={`form-group mb-0`}>
									<div className="d-flex align-items-center flex-wrap mb-2">
										<label className="mb-2 mr-3">
											{translationService.translate("TR_LOGIN_PAGE_TEXT_COLOR")}
										</label>
										<div
											className="border rounded "
											style={{
												width: "100px",
												height: "30px",
												backgroundColor: values.textColor,
											}}
										/>
									</div>
									<div className="bg-white d-flex align-items-center border rounded p-3">
										<CirclePicker
											circleSize={45}
											className="w-auto custom-circle-picker"
											colors={[
												"#3a3a3a",
												"#686868",
												"#4d4d4d",
												"#999999",
												"#292929",
												"#575757",
												"#7a7a7a",
												"#252525",
											]}
											onChangeComplete={color => {
												onChangeColor(color.hex, "textColor");
											}}
										/>
										<div className="mindalay--color-picker w-auto">
											<div
												className="color-picker"
												onClick={() => {
													document.getElementById("textColor") &&
														document.getElementById("textColor").click();
												}}>
												<input
													id="textColor"
													type="color"
													className="ml-3"
													value={values.textColor}
													style={{ border: "1px solid #ffffff" }}
													onChange={e => {
														onChangeColor(e.target.value, "textColor");
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12">
						<hr />
						<ActionButton
							spinnerId={spinnerId}
							disabled={!isChange}
							type="submit"
							className="mindalay--btn-dark position-relative mb-3"
							name={translationService.translate("TR_SAVE")}
						/>
					</div>
				</div>
			</form>
		</div>
	) : null;
};

export default ColorForm;

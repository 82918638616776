import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ArrowBackSvg from '../../Components/Svg/arrowBackSvg';
import { HELP_KEY } from '../../Constants/urlKeys';
import TranslationService from '../../Services/translationService';
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner } from '../../Store/Actions/spinner';
import ApiService from './../../Services/apiService';
import AlertService from '../../Services/alertService';
import { ERROR_KEY } from '../../Constants/mainKeys';
import Parser from 'html-react-parser';
import { withRouter } from "react-router-dom";
import { FAQ_PROPERTY_ID_KEY } from '../../Constants/mainKeys';
import useScroll from '../../Components/Hooks/UseScroll';
import HelpLeftMenu from './Components/HelpLeftMenu';
import MainService from '../../Services/mainService';
import { setFaqPageContent, setPageContent, setTopicPageContent } from '../../Store/Actions/help';

const Topic = (props) => {

  const scrollY = useScroll();
  const containerRef = useRef();
  const dispatch = useDispatch();
  const topicId = props.match.params.topicId;
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const [translationService, setTranslationService] = useState(null);
  const [currentTopicContent, setCurrentTopicContent] = useState(null);
  const { pageContent } = useSelector(state => state.help);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getPageById(topicId);
  }, [topicId]);

  useEffect(() => {
    if (MainService.isShowOnlyB2B()) {
      getHelpPageContent();
    }
    pageContent && getStudentFaqContent(pageContent);
  }, [props.match.params.pageName]);

  const getStudentFaqContent = (pageContent) => {
    if (!pageContent.children || (pageContent.children && !pageContent.children.length)) { return; }
    const currentChild = pageContent.children.find(pageContentChild => pageContentChild.uri === props.match.params.pageName);
    if (!currentChild) { return }
    if (currentChild && (!currentChild.children || (currentChild.children && !currentChild?.children.length)) && !currentChild?.pageContentResponses?.length) {
      dispatch(setFaqPageContent(null));
      dispatch(setTopicPageContent(null));
    } else {
      if (currentChild.children.length) {
        currentChild.children.forEach(currentChildItem => {
          if (currentChildItem.pageContentResponses && currentChildItem.pageContentResponses.length) {
            //FAQ
            dispatch(setFaqPageContent(currentChildItem))
          }
          if (currentChildItem.children && currentChildItem.children.length) {
            //TOPIC
            dispatch(setTopicPageContent(currentChildItem));
          }
        })
      }
    }
  }

  const getHelpPageContent = () => {
    (MainService.isShowOnlyB2B() ? ApiService.getHelpPageContentBySpecialDomain() : ApiService.getHelpPageContent()).then(response => {
      if (response.data) {
        getStudentFaqContent(response.data);
        if (response.data.children.length === 1) {
          const data = { ...response.data }
          if (data.children[0].children && data.children[0].children.length) {
            //TOPIC
            dispatch(setTopicPageContent(data.children[0]));
          }
        }
      }
    }).catch(error => getFail(error));
  }

  const getPageById = (topicId) => {
    if (!topicId) { return; }
    const spinnerId = uuid();
    ApiService.getPageById(topicId).then(response => {
      if (response.data) {
        setCurrentTopicContent(response.data);
      }
      dispatch(removePageSpinner(spinnerId))
    }).catch(error => getFail(error, spinnerId))
  }

  const scrollToCurrentPosition = (id) => {
    const elems = [...document.getElementsByTagName('h2')];
    const currentElement = elems.find(elem => +elem.id === +id);
    currentElement && window.scrollTo({
      top: currentElement?.getBoundingClientRect().top + document.documentElement.scrollTop - 200,
      behavior: "smooth"
    });
    setTimeout(() => { localStorage.removeItem(FAQ_PROPERTY_ID_KEY) });
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    error && props.history.push(`/${language}/${HELP_KEY}/${props.match.params.pageName}`)
    dispatch(removePageSpinner(spinnerId));
  }

  return translationService && currentTopicContent ?
    <div className='help-container'>
      <div className="container mt-4">
        <div className="row">
          <div className="col-12 content-title">
            <div className="d-flex align-items-center">
              {
                !MainService.isShowOnlyB2B() ?
                  <Link to={`/${language}/${HELP_KEY}/${props.match.params.pageName}`} title={translationService.translate("TR_BACK")}><ArrowBackSvg /></Link>
                  : null
              }
              <h2 className="content-title p-0">{translationService.translate(currentTopicContent.pageTitle) ? translationService.translate(currentTopicContent.pageTitle) : translationService.translate(currentTopicContent.name) ? translationService.translate(currentTopicContent.name) : currentTopicContent.name}</h2>
            </div>
          </div>
        </div>
        <hr />
        {
          currentTopicContent.pageContentResponses && currentTopicContent.pageContentResponses.length ?
            <div className="row">
              <div className="col-md-9 col-12">
                <div id="full-content-container" className="faq-body" ref={containerRef}>
                  {
                    currentTopicContent.pageContentResponses.map(pageContentResponse => {
                      if (pageContentResponse.fullContent) {
                        return <div key={pageContentResponse.id}>
                          <div className="row">
                            <div className="col-12">
                              <h2 id={pageContentResponse.id} className="section-title mb-2">
                                {
                                  translationService.translate(pageContentResponse.name) ? translationService.translate(pageContentResponse.name) : pageContentResponse.name
                                }
                              </h2>
                            </div>
                            <div className="col-12">
                              {Parser(pageContentResponse.fullContent)}
                            </div>
                            <div className="col-12">
                              <hr />
                            </div>
                          </div>
                        </div>
                      } else { return; }
                    })
                  }
                </div>
              </div>
              <div className="col-md-3 col-12">
                <ul className={`list-unstyled ${scrollY > 70 ? "list-fixed" : ""}`}>
                  {
                    currentTopicContent.pageContentResponses.map(pageContentResponse => {
                      return <li key={pageContentResponse.id} className="mt-3 test">
                        <Link
                          to="#"
                          onClick={() => scrollToCurrentPosition(pageContentResponse.id)}
                        >
                          {
                            translationService.translate(pageContentResponse.name) ? translationService.translate(pageContentResponse.name) : pageContentResponse.name
                          }
                        </Link>
                      </li>
                    })
                  }
                </ul>
              </div>
            </div>
            : null
        }
      </div>
    </div>
    : null
}

export default withRouter(Topic);

import React, { Component } from 'react';
import { connect } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TranslationService from "../../Services/translationService";
import FileLibraryView from './fileLibraryView';
import FileLibraryCategoryView from './fileLibraryCategoryView';
import ApiService from './../../Services/apiService';
import { addPartialViewSpinner, removePartialViewSpinner } from './../../Store/Actions/spinner';
import AlertService from '../../Services/alertService';
import { ACTIVE_TAB_KEY, ERROR_KEY, FILE_LIBRARY_CATEGORY_TYPE_KEY } from './../../Constants/mainKeys';
import uuid from 'react-uuid'
import AttachFileToStudent from '../../OrganizationPages/FileLibrary/FileLibraryComponents/attachFileToStudent';
import { showOrHideHelpAlert } from './../../Store/Actions/main';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import _InfoSvg from './../../Components/Svg/_infoSvg';
import PageInfoBlock from '../../Components/PageInfoBlock/PageInfoBlock';

class FileLibrary extends Component {

  state = {
    key: 'file-library',
    fileCategories: [],
    translationService: null,
  };

  componentDidMount() {
    this.setTranslations();
    this.getFileCategories();
    if (localStorage.getItem(ACTIVE_TAB_KEY)) {
      this.setState({ key: localStorage.getItem(ACTIVE_TAB_KEY) }, () => {
        localStorage.removeItem(ACTIVE_TAB_KEY);
      })
    }
  };

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getFileCategories = () => {
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getCategoriesByType(FILE_LIBRARY_CATEGORY_TYPE_KEY).then(response => {
      if (response.data) {
        this.setState({ fileCategories: [...response.data] });
      } else {
        this.setState({ fileCategories: [] });
      }
      this.props.removePartialViewSpinner(spinnerId);
    }).catch(e => this.getFail(spinnerId, e));
  }

  getFail = (spinnerId, error) => {
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  };

  render() {
    const { translationService, key, fileCategories } = this.state;
    const { user } = this.props;

    return translationService ?
      <div className={user.isOrganizationUser ? "container" : ""}>
        <div className={user.isOrganizationUser ? "row mt-4" : ""}>
          <div className='col-12'>
            <h2 className="section-title">{translationService.translate("TR_FILE_LIBRARY")}</h2>
            <PageInfoBlock
              pageTitle={translationService.translate("TR_FILE_LIBRARY")}
              pageName="lecturer_file_library"
              translationService={translationService}
            />
          </div>
          <div className='col-12'>
            <hr />
          </div>
          <div className="col-12">
            <div>
              <div className="tabs-wrapper tab-title-group">
                <Tabs
                  activeKey={key}
                  onSelect={(key) => {
                    if (this.state.key && this.state.key !== key) {
                      this.props.showOrHideHelpAlert(false);
                    }
                    this.setState({ key });
                  }}
                >
                  <Tab eventKey="file-library" title={translationService.translate("TR_FILES")}>
                    {/* File library */}
                    <FileLibraryView
                      fileCategories={fileCategories}
                    />
                  </Tab>
                  <Tab eventKey="file-library-folder" title={translationService.translate("TR_FOLDERS")}>
                    {/* Folder */}
                    <FileLibraryCategoryView
                      fileCategories={fileCategories}
                      getFileCategories={this.getFileCategories}
                    />
                  </Tab>
                  {
                    user.isOrganizationUser ?
                      <Tab eventKey="file-library-attach" title={translationService.translate("TR_ATTACH_FILES_TO_STUDENTS")}>
                        {/* Attach files to students */}
                        <AttachFileToStudent />
                      </Tab>
                      : null
                  }
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      : null;
  };
};

const mapStateToProps = state => ({
  buttonSpinners: state.spinner.buttonSpinners,
  translations: state.translation.translations,
  language: state.language.language,
  user: state.user.user,
});

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner,
  showOrHideHelpAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileLibrary);

import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TRANSLATION_TYPES_KEY,
	TR_NO,
	TR_YES,
} from "../Constants/mainKeys";
import AlertService from "../Services/alertService";
import TranslationService from "../Services/translationService";
import {
	removePartialViewSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import Input from "../Components/Inputs/input";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import Parser from "html-react-parser";

const PageContents = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { languages, language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [systemTranslations, setSystemTranslations] = useState([]);
	const [tabKey, setTabKey] = useState("en");
	const [search, setSearch] = useState("");
	const [currentLanguageId, setCurrentLanguageId] = useState(1);
	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getPageContentByLang(1);
		return () => {
			setSystemTranslations([]);
		};
	}, [languages]);

	const filterPages = useMemo(() => {
		if (search.trim().length && systemTranslations.length) {
			return systemTranslations.filter(el =>
				el.name.toLowerCase().includes(search.toLowerCase()),
			);
		} else {
			return systemTranslations;
		}
	}, [systemTranslations, search]);

	const getPageContentByLang = langId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPageContentByLang(langId)
			.then(response => {
				const data = [...response.data];
				setSystemTranslations(data);
			})
			.catch(
				error =>
					error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deletePageContent = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deletePageContent(item.id)
				.then(() => {
					setSystemTranslations(
						systemTranslations.filter(_item => _item.id !== item.id),
					);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};
	return translationService && languages ? (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{/* {translationService.translate("TR_TRANSLATIONS")} */}
						Page contents
					</h2>
					<hr />
				</div>
			</div>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<div className="position-relative">
					<Input
						type="text"
						id="search"
						name="search"
						placeholder={`${translationService.translate("TR_SEARCH")}`}
						inputClassName="pr--5"
						blockClassName="mb-0"
						value={search}
						onChange={event => {
							if (event.target.value.trim().length) {
								setSearch(event.target.value);
							} else {
								setSearch("");
							}
						}}
					/>
					<div
						style={{
							position: "absolute",
							bottom: "50%",
							transform: "translateY(50%)",
							right: "15px",
							pointerEvents: "none",
						}}>
						<i
							className="fa fa-search"
							style={{ color: "var(--mindalay--gray-color-lightened-75)" }}
							aria-hidden="true"></i>
					</div>
				</div>
				<Link
					to={`/${language}/developer/page-content-form`}
					className="mindalay--btn-default">
					Create page content
				</Link>
			</div>
			{languages && languages.length ? (
				<div className="row">
					<div className="col-12">
						<div className="tab-title-group tab-content">
							<Tabs
								activeKey={tabKey}
								onSelect={key => {
									if (key !== tabKey) {
										const currentLang = languages.find(
											lang => lang.isocode2 === key,
										);
										setTabKey(key);
										getPageContentByLang(currentLang.languageId);
										setCurrentLanguageId(currentLang.languageId);
										setSearch("");
									}
								}}>
								{languages.map(languageItem => {
									return (
										<Tab
											key={languageItem.languageId}
											eventKey={languageItem.isocode2}
											title={languageItem.name}>
											{filterPages && filterPages.length ? (
												<div className="mt-4">
													<div className="mindayal-admin-table table-responsive">
														<table className="table">
															<thead>
																<tr>
																	<th className="text-nowrap">
																		{translationService.translate("TR_NAME")}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate(
																			"TR_CATEGORY",
																		)}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate(
																			"TR_SHORT_CONTENT",
																		)}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate(
																			"TR_FULL_CONTENT",
																		)}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate(
																			"TR_SHOW_IN_SLIDER",
																		)}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate("TR_UPDATE")}
																	</th>
																</tr>
															</thead>
															<tbody>
																{filterPages.map(item => {
																	return (
																		<tr
																			key={item.id}
																			className="cursor-default">
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{item.name}
																				</div>
																			</td>
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{translationService.translate(
																						item.pageName,
																					)
																						? translationService.translate(
																								item.pageName,
																						  )
																						: item.pageName}
																				</div>
																			</td>
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{item.shortContent}
																				</div>
																			</td>
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{Parser(item.fullContent)}
																				</div>
																			</td>
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					<span>
																						{item.showInSlider ? (
																							<div className="payment-modal-status-icon-block">
																								<i className="fas fa-check transaction-successful fs-30"></i>
																							</div>
																						) : (
																							<div className="payment-modal-status-icon-block">
																								<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																							</div>
																						)}
																					</span>
																				</div>
																			</td>
																			<td>
																				<div className="table-action-btn-group d-flex align-items-center">
																					<>
																						<Link
																							to={`/${language}/developer/page-content-form/${currentLanguageId}/${item.id}`}
																							title={translationService.translate(
																								"TR_EDIT",
																							)}
																							className="table-action-btn edit-btn">
																							<EditSvg />
																						</Link>
																						<Link
																							to="#"
																							title={translationService.translate(
																								"TR_DELETE",
																							)}
																							className="table-action-btn delete-btn"
																							onClick={e => {
																								e.stopPropagation();
																								deletePageContent(item);
																							}}>
																							<DeleteSvg />
																						</Link>
																					</>
																				</div>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</table>
													</div>
												</div>
											) : search ? (
												<div className="no-data-container mt-3">
													<div className="no-data-wrapper">
														<p>{translationService.translate("TR_NO_DATA")}</p>
														<img src={NoDataImage} alt="/" />
													</div>
												</div>
											) : null}
										</Tab>
									);
								})}
							</Tabs>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(PageContents);

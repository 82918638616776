import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import Filelibrary from "../OrganizationPages/FileLibrary/fileLibrary";
import Webinars from "../OrganizationPages/Webinars/webinars";
import Profile from "../Pages/Profile/profile";
import CourseView from "../OrganizationPages/Courses/courseView";
import PassCourseVideoView from "../Pages/PassCourse/passCourseVideoView";
import PassCourseView from "../Pages/PassCourse/passCourseView";
import WebinarCalendar from "../OrganizationPages/Webinars/webinarCalendar";
import CourseDashboard from "../OrganizationPages/Courses/courseDashboard";
import Home from "../OrganizationPages/Home/home";
import MyCreatedCourses from "../OrganizationPages/Courses/myCreatedCourses";
import MyCourses from "../OrganizationPages/Courses/myCourses";
import QuizForm from "../Pages/Quiz/quizForm";
import TopicMediaForm from "../Pages/CourseTopics/topicMediaForm";
import Students from "../OrganizationPages/Students/students";
import StudentCourses from "../OrganizationPages/Students/studentCourses";
import FooterMenu from "../UserPages/FooterMenu/footerMenu";
import FileLibraryForm from "../Pages/FileLibrary/fileLibraryForm";
import FileLibraryInstructor from "../Pages/FileLibrary/fileLibrary";
import FileLibraryCategoryForm from "../Pages/FileLibrary/fileLibraryCategoryForm";
import Messages from "../OrganizationPages/Messages/messages";
// import QuestionForm from "../Pages/Questions/questionForm";
import Questions from "../Pages/Questions/questions";
import QuizAttempt from "../Pages/Quiz/quizAttempt";
import QuizAttemptView from "../Pages/Quiz/quizAttemptView";
import QuizView from "../Pages/Quiz/quizView";
import NotFoundPage from "../Pages/404/notFoundPage";
import Notifications from "../Pages/Notifications/notifications";
import {
	ALL_VALID_USER_TYPES,
	CURATOR_USER_TYPE,
	DEFAULT_LANGUAGE_KEY,
	LECTURER_USER_TYPE,
	STUDENT_USER_TYPE,
} from "../Constants/mainKeys";
// import Group from "../OrganizationPages/Group/group";
// import Groupform from "../OrganizationPages/Group/groupForm";
import CuratorWebinars from "../OrganizationPages/Webinars/curatorWebinars";
import Webinarform from "../OrganizationPages/Webinars/webinarForm";
import PaymentPackage from "../OrganizationAdminPages/PaymentPackage/paymentPackage";
import Paymentpackagehistory from "../OrganizationAdminPages/PaymentPackage/paymentPackageHistory";
import Finances from "../Pages/Overview/finances";
import CoursesForOrgAdmin from "../OrganizationAdminPages/Courses/CoursesForOrgAdmin";
import RolesService from "../Services/rolesService";
import WebinarCalendarInfo from "../OrganizationPages/Webinars/webinarCalendarInfo";
import _Help from "../Pages/Help/_help";
import ContactUs from "../UserPages/ContactUs/contactUs";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import Document from "../OrganizationPages/Document/document";
import DocumentForm from "../OrganizationPages/Document/documentForm";
import DocumentView from "../OrganizationPages/Document/documentView";
import StudentDocuments from "../OrganizationPages/Students/studentDocuments";
// import Course from "./../OrganizationAdminPages/Courses/CoursePriceForm";
import PaymentSchedule from "../OrganizationPages/PaymentSchedule/paymentSchedule";
import PaymentSchedules from "../OrganizationPages/PaymentSchedule/paymentSchedules";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import MainService from "../Services/mainService";
import GamificationDashboard from "../OrganizationPages/Gamification/Dashboard/GamificationDashboard";
import Resources from "../OrganizationPages/Gamification/Resources/Resources";
import Badges from "../OrganizationPages/Gamification/Badges/Badges";
import Badge from "../OrganizationPages/Gamification/Badges/Badge";
import ShopItems from "../OrganizationPages/Gamification/ShopItems/ShopItems";
import Favorites from "../OrganizationPages/Gamification/Favorites/Favorites";
import ShoppingCart from "../OrganizationPages/Gamification/ShoppingCart/ShoppingCart";
import Leaderboards from "../OrganizationPages/Gamification/Leaderboards/Leaderboards";
import Leaderboard from "../OrganizationPages/Gamification/Leaderboards/Leaderboard";
import ShopItemsAsStudent from "../OrganizationPages/Gamification/ShopItems/ShopItemsAsStudent";
import ResourcesAsStudent from "../OrganizationPages/Gamification/Resources/ResourcesAsStudent";
import Quest from "../OrganizationPages/Gamification/Quests/Quest";
// import Divisions from "../OrganizationPages/Structure/Division/Divisions";
// import DivisionForm from "../OrganizationPages/Structure/Division/DivisionForm";
import AssignmentForm from "../Pages/Assignment/assignmentForm";
import OnGoingWebinars from "../OrganizationPages/Webinars/onGoingWebinars";
import Overview from "../OrganizationPages/Overview/overview";
import LectureAssignments from "../Pages/Assignment/LectureAssignments";
import LectureAssignment from "../Pages/Assignment/LectureAssignment";
import QuizReview from "../OrganizationPages/Students/quizReview";
import QuizAttemp from "../OrganizationPages/Students/quizAttemp";
import About from "../UserPages/About/about";
import TermsAndCondition from "../UserPages/TermsAndCondition/termsAndCondition";
import CookiesPocicy from "../UserPages/Policy/cookiesPolicy";
import PrivacyPolicy from "../UserPages/PrivacyPolicy/privacyPolicy";
import StudentQuizzes from "../OrganizationPages/Students/studentQuizzes";
import RedirectComponent from "../Components/Redirect/redirect";

function LecturerRoute(props) {
	const { role, user } = useSelector(state => state.user);
	const { language } = useSelector(state => state.language);
	const [roleService, setRoleService] = useState(null);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);
	return (
		roleService && (
			<Switch>
				<Route path="/:language/contact-us" exact component={ContactUs} />
				<Route path="/:language/about/:pageId" exact component={About} />
				<Route path="/:language" exact component={Home} />
				<Route
					path="/:language/notifications"
					exact
					component={Notifications}
				/>
				<Route
					path="/:language/cookies-policy/:pageId"
					exact
					component={CookiesPocicy}
				/>
				<Route
					path="/:language/organization-admin/history"
					exact
					component={Paymentpackagehistory}
				/>
				<Route
					path="/:language/privacy-policy/:pageId"
					exact
					component={PrivacyPolicy}
				/>
				<Route
					path="/:language/organization-admin/finances"
					exact
					component={Finances}
				/>
				<Route
					path="/:language/organization-admin/finances/stripesuccess/returnurl"
					exact
					component={Finances}
				/>
				<Route
					path="/:language/organization-admin/finances/stripecancel/returnurl"
					exact
					component={Finances}
				/>
				<Route
					path="/:language/organization-admin/finances/paypalsuccess/returnurl"
					exact
					component={Finances}
				/>
				<Route
					path="/:language/organization-admin/finances/paypalcancel/returnurl"
					exact
					component={Finances}
				/>
				<Route
					path="/:language/organization-admin/finances/cryllexsuccess/returnurl"
					exact
					component={Finances}
				/>
				<Route
					path="/:language/organization-admin/finances/cryllexcancel/returnurl"
					exact
					component={Finances}
				/>
				<Route path="/:language/message" exact component={Messages} />
				<Route
					path="/:language/organization/edit-profile"
					exact
					component={Profile}
				/>
				<Route
					path="/:language/pure/quiz-attempt/:courseId/:quizId"
					exact
					component={QuizAttempt}
				/>
				<Route
					path="/:language/pure/quiz-attempt/view/:courseId/:quizId"
					exact
					component={QuizAttemptView}
				/>
				<Route
					path="/:language/passcourse/:courseId/:studentCourseId"
					exact
					component={PassCourseView}
				/>
				<Route
					path="/:language/passcourse/video/:courseId/:studentCourseId/:topicId"
					exact
					component={PassCourseVideoView}
				/>

				<Route
					path="/:language/organization-admin/payment-package/paypalsuccess/returnurl"
					exact
					component={PaymentPackage}
				/>
				<Route
					path="/:language/organization-admin/payment-package/paypalcancel/returnurl"
					exact
					component={PaymentPackage}
				/>
				<Route
					path="/:language/terms-and-condition/:pageId"
					exact
					component={TermsAndCondition}
				/>
				<Route path="/:language/tariffs/:pageId" exact component={FooterMenu} />
				<Route
					path="/:language/platform-instructions/:pageId"
					exact
					component={FooterMenu}
				/>
				<Route
					path="/:language/company-policy/:pageId"
					exact
					component={FooterMenu}
				/>
				<Route
					path="/:language/personal-data-processing-policy/:pageId"
					exact
					component={FooterMenu}
				/>
				<Route path="/:language/document" exact component={Document} />
				<Route
					path="/:language/document/document-view/:documentId"
					exact
					component={DocumentView}
				/>
				<Route
					path="/:language/document/document-form"
					exact
					component={DocumentForm}
				/>
				<Route
					path="/:language/document/document-form/:documentId"
					exact
					component={DocumentForm}
				/>
				<Route
					path="/:language/organization-admin/courses"
					exact
					component={CoursesForOrgAdmin}
				/>
				<Route
					path="/:language/organization-admin/courses/program/:_programId"
					exact
					component={CoursesForOrgAdmin}
				/>
				<Route
					path="/:language/organization-admin/courses/program/:_programId/:_subjectId"
					exact
					component={CoursesForOrgAdmin}
				/>
				{roleService.checkRole("instructor_course_view") && (
					<Route path="/:language/course" exact component={MyCreatedCourses} />
				)}
				<Route
					path="/:language/organization/coursedashboard"
					exact
					component={CourseDashboard}
				/>
				<Route
					path="/:language/organization/coursedashboard/:courseId"
					exact
					component={CourseDashboard}
				/>
				<Route
					path="/:language/organization/coursedashboard/:courseId/:defaultKey"
					exact
					component={CourseDashboard}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/assignment-form"
					exact
					component={AssignmentForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/assignment-form/:assignmentId"
					exact
					component={AssignmentForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/:topicId/assignment-form/:assignmentId"
					exact
					component={AssignmentForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/:topicId/assignment-form"
					exact
					component={AssignmentForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/:topicId/media-form"
					exact
					component={TopicMediaForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/:topicId/media-form/:mediaId"
					exact
					component={TopicMediaForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/quiz-form"
					exact
					component={QuizForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/quiz-form/:quizId"
					exact
					component={QuizForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/:topicId/quiz-form/:quizId"
					exact
					component={QuizForm}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/:topicId/quiz-form"
					exact
					component={QuizForm}
				/>
				<Route
					path="/:language/student/courses/:studentId/assignments"
					exact
					component={LectureAssignments}
				/>
				<Route
					path="/:language/student/courses/:studentId/assignments/:assignmentId"
					exact
					component={LectureAssignment}
				/>
				<Route
					path="/:language/coursedashboard/:courseId/quiz-view/:quizId"
					exact
					component={QuizView}
				/>
				{!roleService.checkRole("webinar_create") && (
					<Route path="/:language/webinar" exact component={Webinars} />
				)}
				<Route
					path="/:language/webinar/webinar-calendar/:webinarId"
					exact
					component={WebinarCalendar}
				/>
				<Route
					path="/:language/webinar/webinar-calendar/:webinarCalendarId/info"
					exact
					component={WebinarCalendarInfo}
				/>
				<Route
					path="/:language/ongoing-webinars"
					exact
					component={OnGoingWebinars}
				/>
				<Route path="/:language/question" exact component={Questions} />
				<Route
					path="/:language/course/:courseId"
					exact
					component={CourseView}
				/>
				{!roleService.checkRole("file_library_student_view") && (
					<Route
						path="/:language/filelibrary"
						exact
						component={FileLibraryInstructor}
					/>
				)}
				<Route
					path="/:language/filelibrarycategory/form/:id"
					exact
					component={FileLibraryCategoryForm}
				/>
				<Route
					path="/:language/filelibrarycategory/form"
					exact
					component={FileLibraryCategoryForm}
				/>
				<Route
					path="/:language/filelibrary/form/:id"
					exact
					component={FileLibraryForm}
				/>
				<Route
					path="/:language/filelibrary/form"
					exact
					component={FileLibraryForm}
				/>
				<Route path="/:language/student" exact component={Students} />
				<Route
					path="/:language/student/documents/:studentId"
					exact
					component={StudentDocuments}
				/>
				<Route
					path="/:language/gamification/dashboard/:studentId"
					exact
					component={GamificationDashboard}
				/>
				<Route
					path="/:language/gamification/resources"
					exact
					component={Resources}
				/>
				<Route
					path="/:language/gamification/leaderboards/:studentId"
					exact
					component={Leaderboards}
				/>
				<Route
					path="/:language/gamification/leaderboard/:leaderboardId"
					exact
					component={Leaderboard}
				/>
				<Route
					path="/:language/gamification/badges/:studentId"
					exact
					component={Badges}
				/>
				<Route
					path="/:language/gamification/badge/:badgeId"
					exact
					component={Badge}
				/>
				<Route
					path="/:language/gamification/quest/:questId"
					exact
					component={Quest}
				/>
				<Route
					path="/:language/gamification/shop-items/:studentId"
					exact
					component={ShopItemsAsStudent}
				/>
				<Route
					path="/:language/gamification/resources/:studentId"
					exact
					component={ResourcesAsStudent}
				/>
				<Route
					path="/:language/student/courses/:studentId"
					exact
					component={StudentCourses}
				/>
				<Route
					path="/:language/student/:studentId/quizzes/:coursesId"
					exact
					component={StudentQuizzes}
				/>
				<Route
					path="/:language/student/:studentId/quiz/:quizId"
					exact
					component={QuizReview}
				/>
				<Route
					path="/:language/student/:studentId/quiz/:quizId/attempt/:attemptId"
					exact
					component={QuizAttemp}
				/>
				<Route
					path="/:language/student/courses/:studentId/:courseId/assignment"
					exact
					component={LectureAssignments}
				/>
				<Route
					path="/:language/student/courses/:studentId/:courseId/assignment/:assignmentId"
					exact
					component={LectureAssignment}
				/>
				<Route
					path="/:language/authentication/:refreshToken"
					exact
					component={AuthenticationByRefreshToken}
				/>
				<Route path="/:language/not-found/404" exact component={NotFoundPage} />
				<Route
					path="/:language/under-maintenance"
					exact
					component={UnderMaintenancePage}
				/>
				{/* <Route peth="*" component={RedirectComponent} /> */}

				<Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}`} />
			</Switch>
		)
	);
}

export default LecturerRoute;
